import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Wrapper, Title } from './styles';
import Menu from '../../components/Menu';
import EvaluationForm from '../../components/EvaluationForm';
import { getReviewDetail } from '../../services/reviews.service';
import useQuery from '../../helpers/useQuery';
import { checkIfIsProvider } from '../../helpers/checkUserType';

const Evaluation = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const query = useQuery();
  const params = useParams();
  const toCreate = !query.get('criar');
  const [reviewDetail, setReviewDetail] = React.useState();
  const { contract_id } = params;
  React.useEffect(async () => {
    let review = null;
    if (!toCreate) review = await getReviewDetail(params.review_id);
    setReviewDetail(review);
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <Title>Avaliação da CIVIL:</Title>
          <EvaluationForm
            review={reviewDetail}
            contract_id={contract_id}
            isProvider={checkIfIsProvider(user.role)}
          />
        </Wrapper>
      </Menu>
    </Container>
  );
};

export default Evaluation;
