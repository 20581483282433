import styled from 'styled-components';

export const Container = styled.div``;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: calc(100% - 250px);
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/images/hexagon_background.png');
  background-position-x: 110%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  transform: scaleY(-1);
  filter: FlipV;
`;
