import React from 'react';
import { DateTime } from 'luxon';
import {
  Container,
  Title,
  ListBox,
  ListItem,
  ListBoxScroll,
  Button,
  Line,
  ExperienceWrapper,
} from './styles';

export default function DashboardLastList(props) {
  return (
    <Container>
      <Title>Últimas Avaliações:</Title>
      <ListBox hasItens={!props.loading && !!props.list.length}>
        {!props.loading && (
          <ListBoxScroll>
            {props.list &&
              props.list.map((reviewItem, index) => (
                <ListItem key={index} isColumn={props.isCustomer}>
                  <>
                    {props.isProvider &&
                      `Seu serviço foi avaliado por  [
                    ${reviewItem.customer.user.name}] em `}

                    {props.isCustomer &&
                      !props.isAdmin &&
                      `Seu serviço foi avaliado por  [
                    ${reviewItem.provider.fantasy_name}] em `}

                    {props.isAdmin &&
                      `${reviewItem.fantasy_name} quer ser Avaliado`}
                    <br />
                    {DateTime.fromISO(reviewItem.createdAt).toFormat(
                      "dd 'de' LLL"
                    )}
                    {props.isCustomer && !props.isAdmin && (
                      <ExperienceWrapper>
                        <Line />
                        Comentário: {reviewItem.experience}
                      </ExperienceWrapper>
                    )}
                  </>
                  {props.isAdmin && (
                    <Button to={`/fornecedor/${reviewItem._id}/avaliar`}>
                      Avaliar
                    </Button>
                  )}
                </ListItem>
              ))}
            {!props.list.length && <ListItem>Sem Resultados</ListItem>}
          </ListBoxScroll>
        )}
      </ListBox>
    </Container>
  );
}
