import React from 'react';
import {
  Container,
  Label,
  Input,
  ButtonBox,
  Button,
  ErrorLabel,
  Subtitle,
  Register,
} from './styles';

import { formValidation } from './validation';

export default function LoginBoxComponent(props) {
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    email: '',
    password: '',
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) props.onLogin(values);
  };

  return (
    <Container onSubmit={handleSubmit} autoComplete="off">
      <Label htmlFor="email">
        Nome do Usuário:
        <Input
          id="email"
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          required
        />
        <ErrorLabel show={touched.email && errors.email}>
          {errors.email}
        </ErrorLabel>
      </Label>

      <Label htmlFor="password">
        Senha:
        <Input
          id="password"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          required
        />
        <ErrorLabel show={touched.password && errors.password}>
          {errors.password}
        </ErrorLabel>
      </Label>
      <ErrorLabel show={props.errorMessage}>{props.errorMessage}</ErrorLabel>

      <ButtonBox>
        <Button disabled={props.loading} type="submit">
          {props.loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          {!props.loading && <>Login</>}
        </Button>
        {props.showRegisterLink && (
          <>
            <Subtitle>Ainda não é um fornecedor Civil?</Subtitle>
            <Register to="/cadastro">Cadastre-se agora!</Register>
          </>
        )}
      </ButtonBox>
    </Container>
  );
}
