const checkIfIsProvider = (userRoles) => {
  return !!userRoles.includes('provider');
};

const checkIfIsCustomer = (userRoles) => {
  return !!userRoles.includes('client');
};

const checkIfIsAdmin = (userRoles) => {
  return !!userRoles.includes('admin');
};

const checkIfIsCsc = (userRoles) => {
  return !!userRoles.includes('csc');
};
export { checkIfIsProvider, checkIfIsCustomer, checkIfIsAdmin, checkIfIsCsc };
