import React from 'react';
import { connect } from 'react-redux';
import { Container, Background, AereaLabel } from './styles';
import SetNewPasswordForm from '../../components/SetNewPasswordForm';
import UserService from '../../services/user.service';
import useQuery from '../../helpers/useQuery';

const SetNewPassword = () => {
  const query = useQuery();
  const token = query.get('token');
  const user_type = query.get('user_type');
  return (
    <Container>
      <Background />
      <AereaLabel>Defina sua nova senha</AereaLabel>
      {!!token && !!user_type && (
        <SetNewPasswordForm
          setNewPwd={UserService.setNewPwd}
          token={token}
          user_type={user_type}
        />
      )}
      {!token || (!user_type && <>URL Inválida</>)}
    </Container>
  );
};

export default connect()(SetNewPassword);
