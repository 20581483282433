import axios from 'axios';
import authHeader from './auth-header';

const getCustomerMessages = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}reviews/unrated`, {
    headers: authHeader(),
  });
};

const getProviderMessages = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}evaluationscivil/unrated`, {
    headers: authHeader(),
  });
};
export { getCustomerMessages, getProviderMessages };
