import React from 'react';
import AutoComplete from '../AutoComplete';
import { getSearchList } from '../../../../services/search.service';
import { Container } from './styles';
import { checkIfIsCustomer } from '../../../../helpers/checkUserType';

export default function SearchComponent() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [searchList, setSearchList] = React.useState([]);

  React.useEffect(async () => {
    const list = await getSearchList();
    setSearchList(Object.values(list.data));
  }, []);

  return (
    <Container>
      {user && checkIfIsCustomer(user.role) && (
        <AutoComplete loading={!searchList} suggestions={searchList} />
      )}
    </Container>
  );
}
