import React from 'react';
import { Container, Button, ButtonBox } from './styles';

export default function FieldsButtonsComponent() {
  return (
    <Container>
      <ButtonBox>
        <Button to="/login?area=provider">Área do fornecedor Civil</Button>
      </ButtonBox>
      <ButtonBox>
        <Button to="/login">Área do usuário</Button>
      </ButtonBox>
      <ButtonBox>
        <Button to="/login?area=admin">Área do administrador</Button>
      </ButtonBox>
    </Container>
  );
}
