module.exports = {
  colors: {
    color_1: '#e4e4e4',
    color_2: '#d90d00',
    color_2_5: '#d90d0080',
    color_3: '#707070',
    color_4: '#887C7B',
    color_5: '#A3A1A2',
  },
  font: {
    title: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    `,
    text: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    `,
    normal: `
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    `,
  },
};
