import styled from 'styled-components';
import theme from '../../../../helpers/theme';

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px 5px;
  ${theme.font.normal};
  font-size: 14px;
`;

export const ItemsWrapper = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: ${theme.colors.color_3};
  width: 100%;
  z-index: 5;
`;

export const Item = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 2px 5px;
  color: white;
  transition: 0.3s;
  ${theme.font.text}
  &:hover {
    background-color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;
