import React from 'react';
import { Wrapper, Container } from './styles';
import Menu from '../../components/Menu';
import SearchResultList from '../../components/SearchResultList';
import { searchMaterial, searchService } from '../../services/search.service';
import useQuery from '../../helpers/useQuery';
import { history } from '../../helpers/history';

export default function index() {
  const query = useQuery();
  // const user = JSON.parse(localStorage.getItem('user'));
  const [searchResultList, setSearchResultList] = React.useState();
  React.useEffect(async () => {
    const type = query.get('type');
    const searchName = query.get('q');
    if (!searchName || !type) {
      history.push(`/dashboard`);
      window.location.reload();
    }

    let result = null;
    if (type === 'service') result = (await searchService(searchName)).data;
    if (type === 'material') result = (await searchMaterial(searchName)).data;

    setSearchResultList(result);
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <SearchResultList
            searchName={query.get('q')}
            loading={!searchResultList}
            list={searchResultList}
          />
        </Wrapper>
      </Menu>
    </Container>
  );
}
