import axios from 'axios';
import authHeader from './auth-header';

const getSearchList = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}customers/all_materials_services`,
    {}
  );
};

const searchMaterial = (materials) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}providers/materials/list?materials=${materials}`,
    {
      headers: authHeader(),
    }
  );
};

const searchService = (service) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}providers/services/list?service=${service}`,
    {
      headers: authHeader(),
    }
  );
};

export { getSearchList, searchMaterial, searchService };
