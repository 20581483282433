import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.form`
  width: 410px;
  background-color: ${theme.colors.color_5};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 35px 45px 20px;
`;

export const Label = styled.label`
  color: white;
  text-transform: uppercase;
  ${theme.font.normal};
  width: 100%;
`;

export const ErrorLabel = styled.div`
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  ${theme.font.normal}
  font-size: 14px;
  ${(props) => (props.show ? `display: block` : `display: none`)};
`;

export const Input = styled.input`
  width: calc(100% - 20px);
  border: none;
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 10px 0px;
  padding: 0px 10px;
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background-color: white;
  color: ${theme.colors.color_2};
  ${theme.font.title};
  text-transform: uppercase;
  font-size: 16px;
  padding: 5px 25px;
  line-height: 20px;
  margin-top: 15px;
`;

export const Select = styled.select`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;
