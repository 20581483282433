import styled from 'styled-components';
import { ToggleButton, FormCheck } from 'react-bootstrap';
import MultiSelect from 'react-multi-select-component';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import Cep from 'react-simple-cep-mask';
import InputMask from 'react-input-mask';
import theme from '../../helpers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: -25px;
  z-index: 2;
  width: 100%;
  padding-right: 50px;
`;

export const Title = styled.div`
  background: ${theme.colors.color_2};
  color: white;
  ${theme.font.text};
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 20px;
`;

export const Form = styled.form`
  width: 100%;
  background-color: ${theme.colors.color_1};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 35px 45px 50px;
`;

export const Label = styled.label`
  ${theme.font.title};
  width: 100%;
`;

export const Input = styled.input`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const InputPhone = styled(InputMask)`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const InputCEP = styled(Cep)`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const InputCPF = styled(CpfCnpj)`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const Select = styled.select`
  width: calc(100% - 10px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ErrorLabel = styled.div`
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  ${theme.font.normal}
  font-size: 14px;
  ${(props) => (props.show ? `display: block` : `display: none`)};
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Button = styled.button`
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  background-color: white;
  color: ${theme.colors.color_2};
  ${theme.font.title};
  text-transform: uppercase;
  font-size: 16px;
  padding: 5px 25px;
  line-height: 20px;
  margin-top: 15px;
`;

export const ThemedToggleButton = styled(ToggleButton)`
  border-radius: 20px !important;
  background-color: white;
  ${theme.font.title};
  border-color: ${theme.colors.color_3} !important;
  color: ${theme.colors.color_2};
  margin: 0 10px;
  padding: 6px 18px;
  &.active {
    background-color: ${theme.colors.color_2} !important;
    border-color: ${theme.colors.color_2} !important;
  }
`;
export const Checkbox = styled(FormCheck)`
  display: flex;
  align-items: center;
  input,
  label {
    margin-top: 0;
  }
  input {
    transform: scale(1.5);
  }
  label {
    ${theme.font.title}
    color: ${theme.colors.color_2};
    text-transform: uppercase;
    margin-left: 5px;
  }
`;
export const MSelect = styled(MultiSelect)`
  .dropdown-heading {
    height: 30px;
    border-radius: 0;
  }
  .dropdown-container {
    border: 1px solid ${theme.colors.color_3};
    outline: none;
    height: 30px;
    ${theme.font.normal}
    width: calc(100% - 10px);
    border-radius: 0;
    margin: 5px 0;
  }
`;

export const Terms = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  color: ${theme.colors.color_3};
  ${theme.font.title}
  margin-top: 40px;
  font-size: 14px;
`;
