import axios from 'axios';
import authHeader from './auth-header';

const getRequestsList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}contracts/list`, {
    headers: authHeader(),
  });
};

export { getRequestsList };
