import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: 100%;
`;
export const Title = styled.div`
  padding: 5px 10px;
  color: ${theme.colors.color_2};
  background-color: ${theme.colors.color_1};
  width: 100%;
  ${theme.font.title}
  font-size: 18px;
`;
