import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 101px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/images/hexagon_background.png');
  background-position-x: -20%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const Title = styled.div`
  ${theme.font.title}
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  font-size: 28px;
  border-bottom: 2px solid ${theme.colors.color_2};
  padding: 3px 100px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: nowrap;
`;

export const Card = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${theme.colors.color_2};
  flex-direction: column;
  margin: 0 30px;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid white;
  ${theme.font.title}
  color: white;
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
`;

export const CardBody = styled.div`
  display: flex;
  align-items: ${(props) => (props.left ? 'flex-start' : 'center')};
  justify-content: center;
  flex-direction: column;
  color: white;
  ${theme.font.normal}
  height: calc(100% - 40px);
  padding: 5px ${(props) => (props.left ? '15px' : '0')};
  font-size: ${(props) => (props.small ? '12px;' : '16px')};
`;

export const Footer = styled.form`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
`;

export const FooterWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;

export const FooterHeader = styled.div`
  width: 100%;
  ${theme.font.title}
  color: ${theme.colors.color_2};
  padding-left: 10px;
  font-size: 20px;
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 10px;
  padding: 0px 10px;
`;

export const RowInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Textarea = styled.textarea`
  width: calc(100% - 18px);
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  ${theme.font.normal}
  margin: 5px 0px;
  margin-left: 8px;
  padding: 5px 10px;
`;

export const Button = styled.button`
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  background-color: white;
  color: ${theme.colors.color_2};
  ${theme.font.title};
  text-transform: uppercase;
  font-size: 16px;
  padding: 5px 25px;
  line-height: 20px;
  margin-top: 15px;
`;

export const AddressMap = styled.a`
  color: white;
  /* font-weight: bold; */
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;
