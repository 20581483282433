import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  Container,
  Form,
  Label,
  NPSWrapper,
  SubTitle,
  TextArea,
  ExperienceSubTitle,
  ExperienceWrapper,
  LabelText,
  Submit,
  ButtonWrapper,
  ThemedToggleButton,
  ErrorWrapper,
} from './styles';
import NPSSelect from './Components/NPSSelect';

import {
  createCustomerReview,
  createProviderEvaluation,
} from '../../services/reviews.service';
import { history } from '../../helpers/history';
import { checkIfIsProvider } from '../../helpers/checkUserType';

export default function EvaluationForm(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const [NPSScore, setNPSScore] = React.useState({
    deadline: null,
    quality: null,
    price: null,
    payment: null,
    security: null,
    organization: null,
    relationship: null,
    documentation: null,
    experience: null,
  });

  const [radioValue, setRadioValue] = React.useState('0');
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);

  const validateForm = async () => {
    const errorList = [];
    const ProviderNPSScore = NPSScore;
    if (props.isProvider) {
      delete ProviderNPSScore.security;
      delete ProviderNPSScore.price;
    } else {
      delete ProviderNPSScore.payment;
    }

    const formKeys = Object.keys(ProviderNPSScore);
    formKeys.map((item) => {
      if (ProviderNPSScore[item] === null) {
        errorList.push(` ${item} está pendente ou em branco`);
      }
      return item;
    });
    setFormErrors(errorList);
    return errorList;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmitLoading(true);
    if ((await validateForm()).length) {
      setSubmitLoading(false);
      return false;
    }

    const getServiceByUserType = (isProvider) => {
      if (isProvider) return createProviderEvaluation;
      return createCustomerReview;
    };
    await getServiceByUserType(props.isProvider)(props.contract_id, {
      ...NPSScore,
      shared: !!JSON.parse(radioValue),
    }).then(() => {
      setSubmitLoading(false);
      history.push(`/mensagens`);
      window.location.reload();
    });
    return true;
  };

  const radios = [
    { name: 'Sim', value: '1' },
    { name: 'Não', value: '0' },
  ];

  return (
    <Container>
      <Form a={NPSScore} onSubmit={handleSubmit} autoComplete="off">
        {!props.isProvider && (
          <Label>
            <LabelText>O serviço foi concluido?:</LabelText>
            <ButtonGroup toggle>
              {radios.map((radio, idx) => (
                <ThemedToggleButton
                  key={idx}
                  type="radio"
                  variant="secondary"
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ThemedToggleButton>
              ))}
            </ButtonGroup>
          </Label>
        )}
        <SubTitle>
          {checkIfIsProvider(user.role) &&
            'Com base na sua experiência nos quesitos abaixo, avalie o gestor do seu contrato?'}
          {!checkIfIsProvider(user.role) &&
            'Com base na sua experiência nos quesitos abaixo, o quanto você recomendaria a um amigo ou familiar?'}
        </SubTitle>
        <Label>
          <LabelText>Prazo:</LabelText>
          <NPSWrapper>
            <NPSSelect
              score={(value) => setNPSScore({ ...NPSScore, deadline: value })}
            />
          </NPSWrapper>
        </Label>
        <Label>
          <LabelText>Qualidade:</LabelText>
          <NPSWrapper>
            <NPSSelect
              score={(value) => setNPSScore({ ...NPSScore, quality: value })}
            />
          </NPSWrapper>
        </Label>
        {props.isProvider && (
          <Label>
            <LabelText>Pagamento:</LabelText>
            <NPSWrapper>
              <NPSSelect
                score={(value) => setNPSScore({ ...NPSScore, payment: value })}
              />
            </NPSWrapper>
          </Label>
        )}
        {!props.isProvider && (
          <Label>
            <LabelText>Preço:</LabelText>
            <NPSWrapper>
              <NPSSelect
                score={(value) => setNPSScore({ ...NPSScore, price: value })}
              />
            </NPSWrapper>
          </Label>
        )}
        {!props.isProvider && (
          <Label>
            <LabelText>Segurança:</LabelText>
            <NPSWrapper>
              <NPSSelect
                score={(value) => setNPSScore({ ...NPSScore, security: value })}
              />
            </NPSWrapper>
          </Label>
        )}
        <Label>
          <LabelText>Organização:</LabelText>
          <NPSWrapper>
            <NPSSelect
              score={(value) =>
                setNPSScore({ ...NPSScore, organization: value })
              }
            />
          </NPSWrapper>
        </Label>
        <Label>
          <LabelText>Relacionamento:</LabelText>
          <NPSWrapper>
            <NPSSelect
              score={(value) =>
                setNPSScore({ ...NPSScore, relationship: value })
              }
            />
          </NPSWrapper>
        </Label>
        <Label>
          <LabelText>Documentação:</LabelText>
          <NPSWrapper>
            <NPSSelect
              score={(value) =>
                setNPSScore({ ...NPSScore, documentation: value })
              }
            />
          </NPSWrapper>
        </Label>
        <ExperienceSubTitle>Como foi sua experiência</ExperienceSubTitle>
        <ExperienceWrapper>
          <TextArea
            placeholder="Registre aqui sua experiência e compartilhe conosco!"
            onChange={(evt) => {
              setNPSScore({ ...NPSScore, experience: evt.target.value });
            }}
          />
        </ExperienceWrapper>
        {formErrors.length > 0 && (
          <ErrorWrapper>
            Error:
            {formErrors.map((error, k) => (
              <span key={k}>{error}</span>
            ))}
          </ErrorWrapper>
        )}
        <ButtonWrapper>
          <Submit type="submit" disabled={submitLoading}>
            {submitLoading && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {!submitLoading && 'Enviar'}
          </Submit>
        </ButtonWrapper>
      </Form>
    </Container>
  );
}
