import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Wrapper } from './styles';
import Menu from '../../components/Menu';
import ProviderResume from '../../components/ProviderResume';
import { getProvider } from '../../services/provider.service';

export default function index() {
  const params = useParams();
  const [provider, setProvider] = React.useState();

  React.useEffect(async () => {
    const { id } = params;
    const providerData = await getProvider(id);
    setProvider(providerData.data);
  }, []);
  return (
    <Container>
      <Menu>
        <Wrapper>
          {provider && <ProviderResume provider={provider} toApprove />}
        </Wrapper>
      </Menu>
    </Container>
  );
}
