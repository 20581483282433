import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../../helpers/theme';

export const Container = styled.div`
  background-color: ${theme.colors.color_2};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 250px;
  height: 100%;
  position: sticky;
`;

export const Loading = styled.span`
  color: white;
  margin: auto 0;
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 10%;
`;

export const FooterMenu = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15%;
`;

export const Item = styled(Link)`
  ${theme.font.text};
  color: white;
  text-decoration: none;
  width: 100%;
  ${(props) => (props.center ? `text-align: center;` : `text-align: left;`)}
  padding: 8px 30px;
  background: rgba(255, 255, 255, 50%);
  position: relative;
  margin: 2px 0;
  transition: 0.3s;
  :hover {
    transition: 0.3s;
    text-decoration: none;
    color: ${theme.colors.color_2};
  }
`;
