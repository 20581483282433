import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000/api/';

const AuthService = {
  login(email, password, user_type) {
    return axios
      .post(`${API_URL}users/login`, { email, password, user_type })
      .then((response) => {
        if (response.data.usuario.token) {
          localStorage.setItem('user', JSON.stringify(response.data.usuario));
        }
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  logout() {
    localStorage.removeItem('user');
  },
  register(name, cnpj_cpf, email, password, permission) {
    return axios
      .post(`${API_URL}users/register`, {
        name,
        cnpj_cpf,
        email,
        password,
        permission,
      })
      .then((response) => {
        const newLocal = 'userID';
        localStorage.setItem(newLocal, JSON.stringify(response.data.user._id));
        return response.data.user._id;
      });
  },
  registerProvider(
    fantasy_name,
    social_reason,
    address_street,
    address_postalCode,
    contact_cellphone,
    contact_telephone,
    country,
    state,
    city,
    seal,
    service,
    material,
    user
  ) {
    return axios.post(`${API_URL}providers/register`, {
      fantasy_name,
      social_reason,
      address_street,
      address_postalCode,
      contact_cellphone,
      contact_telephone,
      country,
      state,
      city,
      seal,
      service,
      material,
      user,
    });
  },
};

export default AuthService;
