import axios from 'axios';
import authHeader from './auth-header';

const getReviewDetail = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}reviews/${id}`, {
    headers: authHeader(),
  });
};

const createCustomerReview = (contract_id, body) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}reviews?contract=${contract_id}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const createProviderEvaluation = (contract_id, body) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}evaluationscivil?contract=${contract_id}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

export { getReviewDetail, createCustomerReview, createProviderEvaluation };
