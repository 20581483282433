import React from 'react';
import {
  Container,
  Label,
  Input,
  FlexRow,
  ErrorLabel,
  OperatorButton,
  ButtonWrapper,
  BlocksWrapper,
} from './styles';

function Row(props) {
  const { values, touched, errors, handleChange, idx } = props;
  const ChangeValue = (value, type, _idx) => {
    let emptyRow = {};
    if (type === 'code') {
      emptyRow = {
        name: values.iso[_idx].name,
        code: value,
      };
    }
    if (type === 'name') {
      emptyRow = {
        name: value,
        code: values.iso[_idx].code,
      };
    }

    const rowsList = values.iso;
    rowsList[_idx] = emptyRow;

    handleChange({
      target: {
        name: 'iso',
        value: rowsList,
      },
    });
  };
  return (
    <FlexRow>
      <Label htmlFor={`iso-name-${idx}`}>
        Insira o nome da certificação:
        <Input
          id={`iso-name-${idx}`}
          type="text"
          name={`iso-name-${idx}`}
          value={values.iso[idx].name}
          onChange={(_value) => {
            ChangeValue(_value.target.value, 'name', idx);
          }}
        />
        <ErrorLabel show={touched.iso_name && errors.iso_name}>
          {errors.iso_name}
        </ErrorLabel>
      </Label>
      <Label htmlFor={`iso-id-${idx}`}>
        Insira o número de identificação:
        <Input
          id={`iso-id-${idx}`}
          type="text"
          name={`iso-id-${idx}`}
          value={values.iso[idx].code}
          onChange={(_value) => {
            ChangeValue(_value.target.value, 'code', idx);
          }}
        />
        <ErrorLabel show={touched.iso_id && errors.iso_id}>
          {errors.iso_id}
        </ErrorLabel>
      </Label>
    </FlexRow>
  );
}

export default function index(props) {
  const { values, touched, errors, handleChange, show, isEdit } = props;
  const [rows, setRows] = React.useState(values.iso.length);

  const Operator = (value) => {
    const emptyRow = { name: '', code: '' };
    const rowsList = values.iso;

    if (value) {
      rowsList.push(emptyRow);
    } else {
      rowsList.pop();
    }

    handleChange({
      target: {
        name: 'iso',
        value: rowsList,
      },
    });
    setRows(rows + value);
  };

  React.useEffect(() => {
    if (JSON.parse(show)) {
      if (!isEdit) Operator(1);
    } else {
      Operator(-rows);
    }
  }, [show]);

  React.useEffect(() => {
    if (isEdit) {
      setRows(values.iso.length);
      handleChange({
        target: {
          name: 'iso',
          value: values.iso,
        },
      });
    }
  }, []);

  return (
    <Container>
      <BlocksWrapper>
        {[...Array(rows).keys()].map((key) => (
          <Row
            idx={key}
            key={key}
            values={values}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
          />
        ))}
      </BlocksWrapper>
      {(!!JSON.parse(show) || !!values.iso.length) && (
        <ButtonWrapper>
          <OperatorButton
            type="button"
            onClick={() => {
              Operator(-1);
            }}
          >
            -
          </OperatorButton>
          <OperatorButton
            type="button"
            onClick={() => {
              Operator(1);
            }}
          >
            +
          </OperatorButton>
        </ButtonWrapper>
      )}
    </Container>
  );
}
