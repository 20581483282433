import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000/';
class UserService {
  getPublicContent() {
    return axios.get(`${API_URL}api/`);
  }

  getAllProviders() {
    return axios.get(`${API_URL}providers`);
  }

  getUserBoard() {
    return axios.get(`${API_URL}user`, { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(`${API_URL}mod`, { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(`${API_URL}admin`, { headers: authHeader() });
  }

  changeProviders(id, value) {
    return axios.put(`${API_URL}users/${id}/change`, { aprove: value });
  }

  getClient(id) {
    return axios.get(`${API_URL}customers/${id}/user`);
  }

  getMe() {
    return axios.get(`${API_URL}users/me`, { headers: authHeader() });
  }

  createPwdRecovery(body) {
    return axios.post(`${API_URL}users/password-recovery`, body);
  }

  setNewPwd(token, user_type, body) {
    return axios.post(
      `${API_URL}users/recovered-password?token=${token}&user_type=${user_type}`,
      body
    );
  }

  sendContactEmail(body) {
    return axios.post(`${API_URL}users/contact`, body);
  }
}

export default new UserService();
