import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { history } from '../../helpers/history';
import {
  checkIfIsProvider,
  checkIfIsCustomer,
} from '../../helpers/checkUserType';

import {
  Container,
  Form,
  Label,
  Input,
  InputCEP,
  ButtonBox,
  Button,
  ErrorLabel,
  Header,
  Title,
  FlexRow,
  Select,
  MSelect,
  InputPhone,
  ThemedToggleButton,
  Textarea,
  SubLabel,
} from './styles';

// import { formValidation } from './validation';
import { editCustomer } from '../../services/customer.service';
import { editProvider } from '../../services/provider.service';
import MultipleInputBlock from '../MultipleInputBlock';

export default function RegisterForm(props) {
  const [errors, setErrors] = React.useState({});
  const [submitErrors, setSubmitErrors] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    cpf: '',
    company: '',
    office: '',
    address_street: '',
    address_postalCode: '',
    phone_1: '',
    phone_2: '',
    state: '',
    city: '',
    cpnj: '',
    material: [],
    service: [],
    company_presentation: undefined,
    differentials: undefined,
    additional_information: undefined,
    renderedService: undefined,
    facebook: undefined,
    instagram: undefined,
    linkedin: undefined,
    website: undefined,
  });

  const radios = [
    { name: 'Sim', value: '1' },
    { name: 'Não', value: '0' },
  ];

  React.useEffect(async () => {
    if (checkIfIsCustomer(props.profile.user.permission)) {
      setValues({
        ...values,
        name: props.profile.user.name,
        email: props.profile.user.email,
        cpf: props.profile.cpf,
        company: props.profile.company,
        office: props.profile.office,
        address_street: props.profile.address_street,
        address_postalCode: props.profile.address_postalCode,
        phone_1: props.profile.phones[0] || '',
        phone_2: props.profile.phones[1] || '',
        state: props.profile.state,
        city: props.profile.city,
      });
    }
    if (checkIfIsProvider(props.profile.user.permission)) {
      setValues({
        ...values,
        name: props.profile.user.name,
        email: props.profile.user.email,
        cnpj: props.profile.cnpj,
        company: props.profile.company,
        office: props.profile.office,
        address_street: props.profile.address_street,
        address_postalCode: props.profile.address_postalCode,
        phone_1: props.profile.phones[0] || '',
        phone_2: props.profile.phones[1] || '',
        state: props.profile.state._id,
        city: props.profile.city._id,
        fantasy_name: props.profile.fantasy_name,
        social_reason: props.profile.social_reason,
        has_iso: !!props.profile.seal.length,
        iso: props.profile.seal,
        facebook: props.profile.socialNetwork.facebook,
        instagram: props.profile.socialNetwork.instagram,
        linkedin: props.profile.socialNetwork.linkedin,
        website: props.profile.socialNetwork.website,
        company_presentation: props.profile.presentation.company_presentation,
        differentials: props.profile.presentation.differentials,
        additional_information:
          props.profile.presentation.additional_information,
        service: props.materialAndService
          .filter((item) => props.profile.service.includes(item.name))
          .map((item) => ({
            label: item.name,
            value: item._id,
          })),
        material: props.materialAndService
          .filter((item) => props.profile.material.includes(item.name))
          .map((item) => ({
            label: item.name,
            value: item._id,
          })),
      });
    }
  }, [props]);

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    // setErrors(formValidation(values, errors, touched).errors);
    // setTouched(formValidation(values, errors, touched).touched);

    // const formIsValid =
    //   !Object.values(formValidation(values, errors, touched).errors).length &&
    //   Object.values(formValidation(values, errors, touched).touched).length ===
    //     Object.values(values).length &&
    //   Object.values(formValidation(values, errors, touched).touched).every(
    //     (isTouched) => isTouched === true
    //   );

    if (values.password === values.confirm_password) {
      let toSend = {
        user: {
          name: values.name,
          email: values.email,
        },
        password: values.password,
        address_street: values.address_street,
        address_postalCode: values.address_postalCode,
        phones: [values.phone_1, values.phone_2],
      };
      const { country } = props.states.shift();

      if (checkIfIsCustomer(props.profile.user.permission)) {
        toSend = {
          ...toSend,
          company: values.company,
          office: values.office,
        };
      }

      if (checkIfIsProvider(props.profile.user.permission)) {
        toSend = {
          ...toSend,
          fantasy_name: values.fantasy_name,
          address_street: values.address_street,
          address_postalCode: values.address_postalCode,
          phones: [values.phone_1, values.phone_2],
          service: values.service.map((s) => s.label),
          material: values.material.map((m) => m.label),
          country,
          state: values.state,
          city: values.city,
          externalCustomer: [
            {
              company: values.fantasy_name,
              responsible: values.name,
              email: values.email,
            },
          ],
          socialNetwork: {
            facebook: values.facebook === '' ? ' ' : values.facebook,
            instagram: values.instagram === '' ? ' ' : values.instagram,
            linkedin: values.linkedin === '' ? ' ' : values.linkedin,
            website: values.website === '' ? ' ' : values.website,
          },
          presentation: {
            company_presentation:
              values.company_presentation === ''
                ? ' '
                : values.company_presentation,
            differentials:
              values.differentials === '' ? ' ' : values.differentials,
            additional_information:
              values.additional_information === ''
                ? ' '
                : values.additional_information,
          },
          renderedService:
            values.renderedService === '' ? ' ' : values.renderedService,
        };

        if (values.has_iso) {
          toSend = {
            ...toSend,
            seal: values.iso,
          };
        }
      }

      if (checkIfIsCustomer(props.profile.user.permission)) {
        await editCustomer(
          props.profile._id,
          country,
          values.state,
          values.city,
          toSend
        )
          .then(() => {
            history.push('/dashboard');
            window.location.reload();
          })
          .catch((err) => {
            setSubmitErrors(JSON.stringify(err.response.data.error));
          });
      }
      if (checkIfIsProvider(props.profile.user.permission)) {
        await editProvider(props.profile._id, toSend)
          .then(() => {
            history.push('/dashboard');
            window.location.reload();
          })
          .catch((err) => {
            setSubmitErrors(JSON.stringify(err.response.data.error));
          });
      }
    } else if (values.password !== values.confirm_password) {
      setErrors({
        confirm_password: 'Senha e confirmação de senha devem ser iguais.',
        password: 'Senha e confirmação de senha devem ser iguais.',
      });
      setTouched({ confirm_password: true, password: true });
    }
  };
  return (
    <Container>
      <Header>
        <Title>Editar Perfil </Title>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        {checkIfIsCustomer(props.profile.user.permission) && (
          <FlexRow>
            <Label htmlFor="name">
              Nome
              <Input
                id="name"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                required
              />
              <ErrorLabel show={touched.name && errors.name}>
                {errors.name}
              </ErrorLabel>
            </Label>
            <Label htmlFor="cpf">
              Matricula/CNPJ
              <Input
                id="cpf"
                type="tel"
                name="cpf"
                value={values.cpf}
                maxlength="14"
                disabled
              />
            </Label>
          </FlexRow>
        )}
        {checkIfIsProvider(props.profile.user.permission) && (
          <>
            <Label htmlFor="social_reason">
              Razão social
              <Input
                id="social_reason"
                type="text"
                name="social_reason"
                value={values.social_reason}
                disabled
              />
            </Label>

            <Label htmlFor="fantasy_name">
              Nome Fantasia
              <Input
                id="fantasy_name"
                type="text"
                name="fantasy_name"
                value={values.fantasy_name}
                onChange={handleChange}
                required
              />
              <ErrorLabel show={touched.fantasy_name && errors.fantasy_name}>
                {errors.fantasy_name}
              </ErrorLabel>
            </Label>

            <FlexRow>
              <Label htmlFor="cnpj">
                CPNJ
                <Input
                  id="cnpj"
                  type="tel"
                  name="cnpj"
                  value={values.cnpj}
                  disabled
                />
              </Label>
              <Label htmlFor="name">
                Responsável
                <Input
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  required
                />
                <ErrorLabel show={touched.name && errors.name}>
                  {errors.name}
                </ErrorLabel>
              </Label>
            </FlexRow>
          </>
        )}
        <FlexRow>
          <Label htmlFor="email">
            Email
            <Input
              id="email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.email && errors.email}>
              {errors.email}
            </ErrorLabel>
          </Label>
        </FlexRow>
        {checkIfIsCustomer(props.profile.user.permission) && (
          <FlexRow>
            <Label htmlFor="company">
              Empresa
              <Input
                id="company"
                type="text"
                name="company"
                value={values.company}
                onChange={handleChange}
                required
              />
              <ErrorLabel show={touched.company && errors.company}>
                {errors.company}
              </ErrorLabel>
            </Label>
            <Label htmlFor="office">
              Cargo
              <Input
                id="office"
                type="text"
                name="office"
                value={values.office}
                onChange={handleChange}
                required
              />
              <ErrorLabel show={touched.office && errors.office}>
                {errors.office}
              </ErrorLabel>
            </Label>
          </FlexRow>
        )}
        <FlexRow>
          <Label htmlFor="state">
            Estado
            <Select
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange}
              required
            >
              {props.states &&
                props.states.map((_state, index) => (
                  <option key={index} value={_state._id}>
                    {_state.description}
                  </option>
                ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
          <Label htmlFor="city">
            Cidade
            <Select
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              required
              disabled={!values.state}
            >
              {values.state &&
                props.states
                  .find((state) => state._id === values.state)
                  .cities.map((_city, index) => (
                    <option key={index} value={_city._id}>
                      {_city.description}
                    </option>
                  ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="address_street">
            Endereço
            <Input
              id="address_street"
              type="text"
              name="address_street"
              value={values.address_street}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.address_street && errors.address_street}>
              {errors.address_street}
            </ErrorLabel>
          </Label>
          <Label htmlFor="address_postalCode">
            CEP
            <InputCEP
              id="address_postalCode"
              type="tel"
              name="address_postalCode"
              value={values.address_postalCode}
              onChange={(_value) => {
                handleChange({
                  target: {
                    name: 'address_postalCode',
                    value: _value,
                  },
                });
              }}
              required
            />
            <ErrorLabel
              show={touched.address_postalCode && errors.address_postalCode}
            >
              {errors.address_postalCode}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="phone_1">
            Telefone 1
            <InputPhone
              mask={
                values.phone_1.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_1"
              type="tel"
              name="phone_1"
              value={values.phone_1}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.phone_1 && errors.phone_1}>
              {errors.phone_1}
            </ErrorLabel>
          </Label>
          <Label htmlFor="phone_2">
            Telefone 2
            <InputPhone
              mask={
                values.phone_1.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_2"
              type="text"
              name="phone_2"
              value={values.phone_2}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.phone_2 && errors.phone_2}>
              {errors.phone_2}
            </ErrorLabel>
          </Label>
        </FlexRow>
        {checkIfIsProvider(props.profile.user.permission) && (
          <>
            <Label htmlFor="has_iso">
              Possui o selo ISO 9001 (Sistema de Gestão da Qualidade)
              implementado na sua empresa?
              <ButtonGroup toggle>
                {radios.map((radio, idx) => (
                  <ThemedToggleButton
                    key={idx}
                    type="radio"
                    variant="secondary"
                    name="has_iso"
                    value={radio.value}
                    // eslint-disable-next-line eqeqeq
                    checked={values.has_iso == radio.value}
                    onChange={handleChange}
                  >
                    {radio.name}
                  </ThemedToggleButton>
                ))}
              </ButtonGroup>
              <ErrorLabel show={touched.has_iso && errors.has_iso}>
                {errors.has_iso}
              </ErrorLabel>
            </Label>

            {!!values.iso && (
              <MultipleInputBlock
                values={values}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                show={values.has_iso}
                isEdit
              />
            )}

            <Label htmlFor="material">
              Sou um fornecedor de materiais:
              {!!props.materialAndService && (
                <MSelect
                  onChange={(_value) => {
                    handleChange({
                      target: {
                        name: 'material',
                        value: _value,
                      },
                    });
                  }}
                  value={values.material}
                  labelledBy="Select"
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: 'Selecione aqui o tipo de material',
                    allItemsAreSelected: 'Todos os itens estão selecionados.',
                    selectAll: 'Selecionar Todos',
                    search: 'Pesquisar',
                    clearSearch: 'Limpar busca',
                  }}
                  options={props.materialAndService
                    .filter((item) => item.type === 'material')
                    .map((item) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                />
              )}
              <ErrorLabel show={touched.material && errors.material}>
                {errors.material}
              </ErrorLabel>
            </Label>
            <Label htmlFor="service">
              Sou um prestador de serviços:
              {!!props.materialAndService && (
                <MSelect
                  onChange={(_value) => {
                    handleChange({
                      target: {
                        name: 'service',
                        value: _value,
                      },
                    });
                  }}
                  value={values.service}
                  labelledBy="Select"
                  hasSelectAll={false}
                  overrideStrings={{
                    selectSomeItems: 'Selecione aqui o tipo de serviço',
                    allItemsAreSelected: 'Todos os itens estão selecionados.',
                    selectAll: 'Selecionar Todos',
                    search: 'Pesquisar',
                    clearSearch: 'Limpar busca',
                  }}
                  options={props.materialAndService
                    .filter((item) => item.type === 'service')
                    .map((item) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                />
              )}
              <ErrorLabel show={touched.service && errors.service}>
                {errors.service}
              </ErrorLabel>
            </Label>
            <FlexRow>
              <Label htmlFor="facebook">
                Facebook
                <Input
                  id="facebook"
                  type="text"
                  name="facebook"
                  value={values.facebook}
                  onChange={handleChange}
                />
                <ErrorLabel show={touched.facebook && errors.facebook}>
                  {errors.facebook}
                </ErrorLabel>
              </Label>
              <Label htmlFor="instagram">
                Instagram
                <Input
                  id="instagram"
                  type="text"
                  name="instagram"
                  value={values.instagram}
                  onChange={handleChange}
                />
                <ErrorLabel show={touched.instagram && errors.instagram}>
                  {errors.instagram}
                </ErrorLabel>
              </Label>
            </FlexRow>

            <FlexRow>
              <Label htmlFor="linkedin">
                Linkedin
                <Input
                  id="linkedin"
                  type="text"
                  name="linkedin"
                  value={values.linkedin}
                  onChange={handleChange}
                />
                <ErrorLabel show={touched.linkedin && errors.linkedin}>
                  {errors.linkedin}
                </ErrorLabel>
              </Label>
              <Label htmlFor="website">
                Website
                <Input
                  id="website"
                  type="text"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                />
                <ErrorLabel show={touched.website && errors.website}>
                  {errors.website}
                </ErrorLabel>
              </Label>
            </FlexRow>

            <Label htmlFor="company_presentation">
              Apresentação da Empresa
              <Textarea
                id="company_presentation"
                type="text"
                name="company_presentation"
                value={values.company_presentation}
                onChange={handleChange}
                rows="4"
              />
              <ErrorLabel
                show={
                  touched.company_presentation && errors.company_presentation
                }
              >
                {errors.company_presentation}
              </ErrorLabel>
            </Label>

            <Label htmlFor="differentials">
              Diferenciais
              <Textarea
                id="differentials"
                type="text"
                name="differentials"
                value={values.differentials}
                onChange={handleChange}
                rows="4"
              />
              <ErrorLabel show={touched.differentials && errors.differentials}>
                {errors.differentials}
              </ErrorLabel>
            </Label>

            <Label htmlFor="additional_information">
              Informaçõs Adicionais
              <Textarea
                id="additional_information"
                type="text"
                name="additional_information"
                value={values.additional_information}
                onChange={handleChange}
                rows="4"
              />
              <ErrorLabel
                show={
                  touched.additional_information &&
                  errors.additional_information
                }
              >
                {errors.additional_information}
              </ErrorLabel>
            </Label>
            <Label htmlFor="renderedService">
              Serviços prestados anteriormente à Civil
              <SubLabel>
                Indique a unidade, serviço prestado e contato da unidade
              </SubLabel>
              <Textarea
                id="renderedService"
                type="text"
                name="renderedService"
                value={values.renderedService}
                onChange={handleChange}
                rows="4"
              />
              <ErrorLabel
                show={touched.renderedService && errors.renderedService}
              >
                {errors.renderedService}
              </ErrorLabel>
            </Label>
          </>
        )}
        <FlexRow>
          <Label htmlFor="password">
            Senha:
            <Input
              id="password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.password && errors.password}>
              {errors.password}
            </ErrorLabel>
          </Label>
          <Label htmlFor="confirm_password">
            Confirmação da Senha:
            <Input
              id="confirm_password"
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              required
            />
            <ErrorLabel
              show={touched.confirm_password && errors.confirm_password}
            >
              {errors.confirm_password}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <ButtonBox>
          <ErrorLabel show={submitErrors}>{submitErrors}</ErrorLabel>
          <Button disabled={props.loading} type="submit">
            {props.loading && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {!props.loading && <>Salvar</>}
          </Button>
        </ButtonBox>
      </Form>
    </Container>
  );
}
