import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { clearMessage as clearMessageWhenChagingLocation } from '../actions/message';
import { history } from '../helpers/history';
import GuardedRoute from './guardedRoute';
import RedirectIfAuthenticated from './redirectIfAuthenticated';

import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import DashboardPage from '../pages/Dashboard';
import RequestsPage from '../pages/Requests';
import MessagesPage from '../pages/Messages';
import ProfilePage from '../pages/Profile';
import EvaluationPage from '../pages/Evaluation';
import SearchResultPage from '../pages/SearchResult';
import LogoutPage from '../pages/Logout';
import RemeberPasswordPage from '../pages/RemeberPassword';
import ProviderPage from '../pages/Provider';
import RegistrationNotCompletedPage from '../pages/RegistrationNotCompleted';
import RegisterPage from '../pages/Register';
import ProviderToAprovePage from '../pages/ProviderToAprove';
import CompleteRegisterPage from '../pages/CompleteRegister';
import ProviderListPage from '../pages/ProviderList';
import SetNewPasswordPage from '../pages/SetNewPassword';
import CreateCustomerPage from '../pages/CreateCustomer';
import CreateContractPage from '../pages/CreateContract';
import ReportsPage from '../pages/Reports';
import ContactPage from '../pages/Contact';

export default function withRoutes(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      history.listen(() => {
        props.dispatch(clearMessageWhenChagingLocation());
      });
    }

    render() {
      return (
        <BrowserRouter history={history}>
          <WrappedComponent {...this.props} />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/contato" component={ContactPage} />
            <Route
              exact
              path="/cadastro-em-avaliacao"
              component={RegistrationNotCompletedPage}
            />
            <RedirectIfAuthenticated
              exact
              path="/login"
              component={LoginPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <RedirectIfAuthenticated
              exact
              path="/nova-senha"
              component={SetNewPasswordPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <RedirectIfAuthenticated
              exact
              path="/cadastro"
              component={RegisterPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <RedirectIfAuthenticated
              exact
              path="/esqueci-minha-senha"
              component={RemeberPasswordPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/logout"
              component={LogoutPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/criar-usuario"
              component={CreateCustomerPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/dashboard"
              component={DashboardPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/relatorios"
              component={ReportsPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/contratos"
              component={RequestsPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/fornecedor/:id/avaliar"
              component={ProviderToAprovePage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/completar-cadastro"
              component={CompleteRegisterPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/fornecedores"
              component={ProviderListPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/fornecedor/:id"
              component={ProviderPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/pesquisa"
              component={SearchResultPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/mensagens"
              component={MessagesPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/avaliacao/:contract_id/criar"
              component={EvaluationPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/criar-contrato"
              component={CreateContractPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              path="/avaliacao/:review_id"
              component={EvaluationPage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
            <GuardedRoute
              exact
              path="/perfil"
              component={ProfilePage}
              auth={this.props.isLoggedIn}
              user={this.props.user}
            />
          </Switch>
        </BrowserRouter>
      );
    }
  };
}
