import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;

export const Title = styled.h2`
  ${theme.font.title};
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
`;

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderLeftSide = styled.div``;

export const HeaderRightSide = styled.div``;

export const ProviderEvaluation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

export const ProviderEvaluationAverage = styled.span`
  display: inline-flex;
`;

export const ProviderEvaluationTotal = styled.span`
  ${theme.font.text};
  margin-left: 10px;
`;
export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
export const BodyLeft = styled.div``;
export const BodyRight = styled.div`
  width: 400px;
`;
export const ProviderInfosWrapper = styled.div`
  margin-bottom: 30px;
`;

export const ContactDetailsWrapper = styled.div``;

export const RowInfo = styled.div``;

export const Label = styled.span`
  ${theme.font.title};
`;

export const Data = styled.span`
  max-width: 700px;
  display: inline-flex;
`;

export const ResumeRowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
`;

export const ResumeRowLabel = styled.div`
  ${theme.font.title};
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  width: 45%;
`;

export const ResumeRowBar = styled.div`
  width: 100%;
`;

export const AveragesHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const AveragesTitle = styled.div`
  ${theme.font.title};
  font-size: 20px;
  color: ${theme.colors.color_5};
`;

export const AveragesSubTitle = styled.div`
  ${theme.font.title};
  font-size: 30px;
  background-color: ${theme.colors.color_5};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
`;

export const AveragesWrapper = styled.div`
  width: 100%;
`;

export const FooterWrapper = styled.div``;

export const FooterTitle = styled.div`
  ${theme.font.title};
  font-size: 20px;
  background-color: ${theme.colors.color_1};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  padding: 3px 30px 3px 10px;
  margin-bottom: 20px;
`;

export const CommentsList = styled.div``;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
export const CommentLeft = styled.div``;
export const CommentRight = styled.div`
  margin-left: 20px;
`;

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  background: #c3c3c3;
  border-radius: 50%;
`;

export const CommentBox = styled.div`
  background-color: ${theme.colors.color_1};
  padding: 15px;
`;

export const CommentTitle = styled.div`
  ${theme.font.title};
`;

export const CommentDate = styled.div`
  ${theme.font.title};
  font-size: 12px;
  color: ${theme.colors.color_3};
`;

export const CommentContent = styled.div`
  ${theme.font.normal};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
`;

export const ErrorWrapper = styled.div`
  background-color: ${theme.colors.color_2};
  color: white;
  ${theme.font.title};
  margin: 50px auto;
  text-align: center;
  width: 40%;
`;

export const Button = styled.button`
  margin-left: 15px;
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;

export const CscWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelForm = styled.label`
  ${theme.font.title};
`;

export const FormWrapper = styled.div`
  ${theme.font.title}
`;

export const Select = styled.select`
  width: 300px;
  display: block;
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const Input = styled.input`
  width: 300px;
  display: block;
  border: 1px solid ${theme.colors.color_3};
  outline: none;
  height: 30px;
  ${theme.font.normal}
  margin: 5px 0px;
  padding: 0px 10px;
`;

export const ErrorLabel = styled.div`
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  ${theme.font.normal}
  font-size: 14px;
  ${(props) => (props.show ? `display: block` : `display: none`)};
`;
