import React from 'react';
import {
  Container,
  Header,
  Title,
  ColInfo,
  ListBox,
  ListItem,
  ListBoxScroll,
  ListItemTitle,
  ListItemButton,
} from './styles';

export default function SearchResultList(props) {
  return (
    <Container>
      <Header>
        <Title>Mensagens</Title>
        <ColInfo>
          {props.loading ? 0 : props.list.docs.length} novas mensagens
        </ColInfo>
      </Header>
      <ListBox>
        <ListBoxScroll>
          {!props.loading &&
            props.list.docs.map((item, index) => (
              <ListItem key={index}>
                <ListItemTitle>
                  {item.provider.fantasy_name
                    ? item.provider.fantasy_name
                    : 'Civil'}{' '}
                  - {item.contract.service}
                </ListItemTitle>
                <ListItemButton to={`/avaliacao/${item.contract._id}/criar`}>
                  Avaliar
                </ListItemButton>
              </ListItem>
            ))}
        </ListBoxScroll>
      </ListBox>
    </Container>
  );
}
