const emailValidationRules = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'O email é obrigatório';
  }
  return 'Por favor digite um email válido';
};
const passwordValidationRules = (password) => {
  if (password.trim() === '') return 'A senha é obrigatória';
  return null;
};
// const cpfValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo CPF é obrigatório';
//   return null;
// };
// const companyValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Empresa é obrigatório';
//   return null;
// };
// const officeValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Cargo é obrigatório';
//   return null;
// };
// const state_ValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Estado é obrigatório';
//   return null;
// };
// const city_ValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Cidade é obrigatório';
//   return null;
// };
// const address_street_ValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Endereço é obrigatório';
//   return null;
// };
// const address_postalCode_ValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo CEP é obrigatório';
//   return null;
// };
// const phone_1_ValidationRules = (value) => {
//   if (value.trim() === '') return 'O campo Telefone 1 é obrigatório';
//   return null;
// };
const confirm_password_ValidationRules = (value) => {
  if (value.trim() === '') return 'A confirmação de senha é obrigatória';
  return null;
};
const name_ValidationRules = (value) => {
  if (!value) return 'O campo Nome é obrigatório';
  return null;
};

const NoValidate = () => null;

const validate = {
  email: emailValidationRules,
  name: name_ValidationRules,
  cpf: NoValidate,
  permission: NoValidate,
  state: NoValidate,
  city: NoValidate,
  address_street: NoValidate,
  address_postalCode: NoValidate,
  phone_1: NoValidate,
  phone_2: NoValidate,
  password: passwordValidationRules,
  confirm_password: confirm_password_ValidationRules,
  company: NoValidate,
  office: NoValidate,
};
const formValidation = (values, errors, touched) => {
  return Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
};

export { formValidation };
