import axios from 'axios';
import authHeader from './auth-header';

const getMenu = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}menu`, {
    headers: authHeader(),
  });
};

export { getMenu };
