import React from 'react';
import {
  Header,
  LeftBox,
  CenterBox,
  RightBox,
  Logo,
  Subtitle,
  Menu,
  MenuItem,
  MenuItemLink,
} from './styles';

import SearchComponent from './components/Search';

export default function HeaderComponent() {
  return (
    <Header>
      <LeftBox>
        <Logo src="/logo.png" />
        <Subtitle>Fornecedores</Subtitle>
      </LeftBox>
      <CenterBox>
        <SearchComponent />
      </CenterBox>
      <RightBox>
        <Menu>
          <MenuItem>
            <MenuItemLink to="/">Início</MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="/contato">Contato</MenuItemLink>
          </MenuItem>
        </Menu>
      </RightBox>
    </Header>
  );
}
