import React from 'react';
import {
  Container,
  Header,
  Title,
  ColInfo,
  ListBox,
  ListItem,
  ListBoxScroll,
  ListItemTitle,
  ListItemPeriod,
} from './styles';

export default function RequestsList(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  // TODO: encontrar nome melhor
  const getContractPerson = (item) => {
    if (user.role.find((r) => r === 'client'))
      return item.provider.fantasy_name;
    if (user.role.find((r) => r === 'provider'))
      return `${item.customer.user.name} [ CIVIL ]`;
    return '';
  };

  return (
    <Container>
      <Header>
        <Title>Histórico de Solicitações</Title>
        <ColInfo>Entrada da Nota</ColInfo>
      </Header>
      <ListBox>
        {!props.loading && (
          <ListBoxScroll>
            {props.list.contracts.docs.map((item, index) => (
              <ListItem to={`/fornecedor/${item.provider._id}`} key={index}>
                <ListItemTitle>{getContractPerson(item)}</ListItemTitle>
                <ListItemPeriod>{item.start_date}</ListItemPeriod>
              </ListItem>
            ))}
          </ListBoxScroll>
        )}
      </ListBox>
    </Container>
  );
}
