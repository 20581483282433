import styled from 'styled-components';

export const Container = styled.span`
  display: inline-flex;
  .svg {
  }

  .svg-circle-bg {
    fill: none;
  }

  .svg-circle {
    fill: none;
  }

  .svg-circle-text {
    font-size: 14px;
    text-anchor: middle;
    fill: black;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
  }
`;
