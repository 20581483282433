import React from 'react';
import {
  Container,
  Header,
  Title,
  ListBox,
  ListItem,
  ListBoxScroll,
  ListItemTitle,
  ListItemButton,
  ListItemInfo,
  ListItemEvaluation,
  ListItemEvaluationAverage,
  ListItemEvaluationTotal,
  ListItemSubTitle,
} from './styles';
import ProgressCircularBar from '../ProgressCircularBar';

export default function MessagesList(props) {
  return (
    <Container>
      <Header>
        <Title>
          {props.searchName ? `${props.searchName} - ` : ''}
          {!props.loading && props.list.docs.length} fornecedor(es) encontrados
        </Title>
      </Header>
      <ListBox>
        <ListBoxScroll>
          {!props.loading &&
            props.list.docs.map((item, index) => (
              <ListItem key={index}>
                <ListItemInfo>
                  <ListItemTitle>{item.fantasy_name}</ListItemTitle>
                  <ListItemSubTitle>Avaliação Geral: </ListItemSubTitle>
                  <ListItemEvaluation>
                    <ListItemEvaluationAverage>
                      <ProgressCircularBar
                        progress={item.valorMedium * 10}
                        size={35}
                        strokeWidth={2}
                        circleOneStroke="#c3c3c3"
                        circleTwoStroke="red"
                      />
                    </ListItemEvaluationAverage>
                    <ListItemEvaluationTotal>
                      {item.quantity_reviews} Avaliações
                    </ListItemEvaluationTotal>
                  </ListItemEvaluation>
                </ListItemInfo>
                <ListItemButton to={`/fornecedor/${item._id}`}>
                  {!props.searchName ? 'Ver' : 'Selecionar'}
                </ListItemButton>
              </ListItem>
            ))}
        </ListBoxScroll>
      </ListBox>
    </Container>
  );
}
