import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { history } from '../../helpers/history';

import {
  Container,
  Form,
  Label,
  Input,
  InputPhone,
  InputCEP,
  InputCpfCnpj,
  ButtonBox,
  Button,
  ErrorLabel,
  Header,
  Title,
  FlexRow,
  Select,
  ThemedToggleButton,
  MSelect,
  Terms,
  Checkbox,
  TermLink,
} from './styles';

import { formValidation } from './validation';
import { createProvider } from '../../services/provider.service';

import MultipleInputBlock from '../MultipleInputBlock';

export default function RegisterForm(props) {
  const radios = [
    { name: 'Sim', value: '1' },
    { name: 'Não', value: '0' },
  ];
  const [errors, setErrors] = React.useState({});
  const [submitErrors, setSubmitErrors] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    social_reason: '',
    fantasy_name: '',
    cnpj: '',
    password: '',
    name: '',
    email: '',
    state: '',
    city: '',
    address_street: '',
    address_postalCode: '',
    phone_1: '',
    phone_2: '',
    confirm_password: '',
    has_iso: null,
    iso_id: '',
    iso_name: '',
    material: [],
    service: [],
    terms: false,
    iso: [],
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid && values.password === values.confirm_password) {
      let toSend = {
        user: {
          name: values.name,
          email: values.email,
        },
        password: values.password,
        cnpj: values.cnpj,
        fantasy_name: values.fantasy_name,
        social_reason: values.social_reason,
        address_street: values.address_street,
        address_postalCode: values.address_postalCode,
        phones: [values.phone_1, values.phone_2],
        service: values.service.map((s) => s.label),
        material: values.material.map((m) => m.label),
        externalCustomer: [
          {
            company: values.fantasy_name,
            responsible: values.name,
            email: values.email,
          },
        ],
      };
      if (JSON.parse(values.has_iso)) {
        toSend = {
          ...toSend,
          seal: values.iso,
        };
      }
      const { country } = props.states.shift();
      await createProvider(country, values.state, values.city, toSend)
        .then(() => {
          history.push('/cadastro-em-avaliacao');
          window.location.reload();
        })
        .catch((err) => {
          // setSubmitErrors(JSON.stringify(err.response.data.errors));
          setSubmitErrors(err.response.data.error);
        });
    } else if (values.password !== values.confirm_password) {
      setErrors({
        confirm_password: 'Senha e confirmação de senha devem ser iguais.',
        password: 'Senha e confirmação de senha devem ser iguais.',
      });
      setTouched({ confirm_password: true, password: true });
    }
  };
  return (
    <Container>
      <Header>
        <Title>Vamos começar criando a sua conta!</Title>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Label htmlFor="social_reason">
          Razão social*
          <Input
            id="social_reason"
            type="text"
            name="social_reason"
            value={values.social_reason}
            onChange={handleChange}
            required
          />
          <ErrorLabel show={touched.social_reason && errors.social_reason}>
            {errors.social_reason}
          </ErrorLabel>
        </Label>

        <Label htmlFor="fantasy_name">
          Nome Fantasia*
          <Input
            id="fantasy_name"
            type="text"
            name="fantasy_name"
            value={values.fantasy_name}
            onChange={handleChange}
            required
          />
          <ErrorLabel show={touched.fantasy_name && errors.fantasy_name}>
            {errors.fantasy_name}
          </ErrorLabel>
        </Label>

        <FlexRow>
          <Label htmlFor="cnpj">
            CPNJ*
            <InputCpfCnpj
              id="cnpj"
              type="tel"
              name="cnpj"
              value={values.cnpj}
              onChange={handleChange}
              required
              minlength="18"
            />
            <ErrorLabel show={touched.cnpj && errors.cnpj}>
              {errors.cnpj}
            </ErrorLabel>
          </Label>
          <Label htmlFor="name">
            Responsável*
            <Input
              id="name"
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.name && errors.name}>
              {errors.name}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="state">
            Estado*
            <Select
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange}
              required
            >
              {props.states &&
                props.states.map((_state, index) => (
                  <option key={index} value={_state._id}>
                    {_state.description}
                  </option>
                ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
          <Label htmlFor="city">
            Cidade*
            <Select
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              required
              disabled={!values.state}
            >
              {values.state &&
                props.states
                  .find((state) => state._id === values.state)
                  .cities.map((_city, index) => (
                    <option key={index} value={_city._id}>
                      {_city.description}
                    </option>
                  ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="address_street">
            Endereço*
            <Input
              id="address_street"
              type="text"
              name="address_street"
              value={values.address_street}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.address_street && errors.address_street}>
              {errors.address_street}
            </ErrorLabel>
          </Label>
          <Label htmlFor="address_postalCode">
            CEP*
            <InputCEP
              id="address_postalCode"
              type="tel"
              name="address_postalCode"
              value={values.address_postalCode}
              onChange={(_value) => {
                handleChange({
                  target: {
                    name: 'address_postalCode',
                    value: _value,
                  },
                });
              }}
              required
            />
            <ErrorLabel
              show={touched.address_postalCode && errors.address_postalCode}
            >
              {errors.address_postalCode}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <Label htmlFor="user.email">
          Email*
          <Input
            id="email"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            required
          />
          <ErrorLabel show={touched.email && errors.email}>
            {errors.email}
          </ErrorLabel>
        </Label>

        <FlexRow>
          <Label htmlFor="phone_1">
            Telefone 1*
            <InputPhone
              mask={
                values.phone_1.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_1"
              type="tel"
              name="phone_1"
              value={values.phone_1}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.phone_1 && errors.phone_1}>
              {errors.phone_1}
            </ErrorLabel>
          </Label>
          <Label htmlFor="phone_2">
            Telefone 2*
            <InputPhone
              mask={
                values.phone_2.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_2"
              type="tel"
              name="phone_2"
              value={values.phone_2}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.phone_2 && errors.phone_2}>
              {errors.phone_2}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="password">
            Senha*:
            <Input
              id="password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.password && errors.password}>
              {errors.password}
            </ErrorLabel>
          </Label>
          <Label htmlFor="confirm_password">
            Confirmação da Senha*:
            <Input
              id="confirm_password"
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              required
            />
            <ErrorLabel
              show={touched.confirm_password && errors.confirm_password}
            >
              {errors.confirm_password}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <Label htmlFor="has_iso">
          Possui o selo ISO 9001 (Sistema de Gestão da Qualidade) ou outra
          certificação implementada na sua empresa?
          <ButtonGroup toggle>
            {radios.map((radio, idx) => (
              <ThemedToggleButton
                key={idx}
                type="radio"
                variant="secondary"
                name="has_iso"
                value={radio.value}
                checked={values.has_iso === radio.value}
                onChange={handleChange}
              >
                {radio.name}
              </ThemedToggleButton>
            ))}
          </ButtonGroup>
          <ErrorLabel show={touched.has_iso && errors.has_iso}>
            {errors.has_iso}
          </ErrorLabel>
        </Label>

        <MultipleInputBlock
          values={values}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          show={values.has_iso}
        />

        <Label htmlFor="material">
          Sou um fornecedor de materiais:
          {props.materialAndService && (
            <MSelect
              onChange={(_value) => {
                handleChange({
                  target: {
                    name: 'material',
                    value: _value,
                  },
                });
              }}
              value={values.material}
              labelledBy="Select"
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: 'Selecione aqui o tipo de material',
                allItemsAreSelected: 'Todos os itens estão selecionados.',
                selectAll: 'Selecionar Todos',
                search: 'Pesquisar',
                clearSearch: 'Limpar busca',
              }}
              options={props.materialAndService
                .filter((item) => item.type === 'material')
                .map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
            />
          )}
          <ErrorLabel show={touched.material && errors.material}>
            {errors.material}
          </ErrorLabel>
        </Label>
        <Label htmlFor="service">
          Sou um prestador de serviços:
          {props.materialAndService && (
            <MSelect
              onChange={(_value) => {
                handleChange({
                  target: {
                    name: 'service',
                    value: _value,
                  },
                });
              }}
              value={values.service}
              labelledBy="Select"
              hasSelectAll={false}
              overrideStrings={{
                selectSomeItems: 'Selecione aqui o tipo de serviço',
                allItemsAreSelected: 'Todos os itens estão selecionados.',
                selectAll: 'Selecionar Todos',
                search: 'Pesquisar',
                clearSearch: 'Limpar busca',
              }}
              options={props.materialAndService
                .filter((item) => item.type === 'service')
                .map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
            />
          )}
          <ErrorLabel show={touched.service && errors.service}>
            {errors.service}
          </ErrorLabel>
        </Label>
        <Terms>
          Ao clicar na caixa de seleção abaixo e enviar o formulário de registro
          da CIVIL, eu concordo com a coleta e o processamento, por parte da
          CIVIL e seus prestadores de serviços, dos meus dados pessoais,
          incluindo dados sensíveis, com o objetivo de avaliar minha
          elegibilidade ao uso, ou continuidade do uso, da plataforma CIVIL, bem
          como as finalidades descritas na{` `}
          <TermLink
            href={`${process.env.REACT_APP_API_URL}providers/contract/terms`}
            target="blank"
          >
            Declaração de Privacidade do Fornecedor Parceiro CIVIL
          </TermLink>
          . Declaro a veracidade e a precisão das informações e documentos
          enviados à CIVIL para fins de registro.
        </Terms>
        <Checkbox
          type="checkbox"
          label="Eu Concordo"
          name="terms"
          value={values.terms}
          onChange={handleChange}
          required
        />
        <ErrorLabel show={touched.terms && errors.terms}>
          {errors.terms}
        </ErrorLabel>
        <ButtonBox>
          <ErrorLabel show={submitErrors}>{submitErrors}</ErrorLabel>
          <Button disabled={props.loading} type="submit">
            {props.loading && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {!props.loading && <>Cadastrar</>}
          </Button>
        </ButtonBox>
      </Form>
    </Container>
  );
}
