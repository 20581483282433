import React from 'react';
import { connect } from 'react-redux';
import { Container, Wrapper } from './styles';
import CreateContractForm from '../../components/CreateContractForm';
import { getEngineerList } from '../../services/customer.service';
import { getProviderList } from '../../services/provider.service';

import Menu from '../../components/Menu';

const CreateContract = () => {
  const [engineerList, setEngineerList] = React.useState();
  const [providerList, setProviderList] = React.useState();

  React.useEffect(async () => {
    const _engineerList = await getEngineerList();
    const _providerList = await getProviderList();
    setProviderList(_providerList.data.docs);
    setEngineerList(_engineerList.data);
  }, []);
  return (
    <Container>
      <Menu>
        <Wrapper>
          <CreateContractForm
            engineerList={engineerList}
            providerList={providerList}
          />
        </Wrapper>
      </Menu>
    </Container>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(CreateContract);
