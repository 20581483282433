const emailValidationRules = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'O email é obrigatório';
  }
  return 'Por favor digite um email válido';
};

const nameValidationRules = (name) => {
  if (name.trim() === '') {
    return 'O Nome é obrigatório';
  }
  return null;
};

const textValidationRules = (text) => {
  if (text.trim() === '') {
    return 'A Mensagem é obrigatória';
  }
  return null;
};

const validate = {
  email: emailValidationRules,
  name: nameValidationRules,
  text: textValidationRules,
};
const formValidation = (values, errors, touched) =>
  Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );

export { formValidation };
