import axios from 'axios';
import authHeader from './auth-header';

const getReviewList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}reviews`, {
    headers: authHeader(),
  });
};
const getEvaluationList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}evaluationscivil`, {
    headers: authHeader(),
  });
};
const getProvidersToApprove = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}providers/approval`, {
    headers: authHeader(),
  });
};

const getProviderAverages = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}providers/averages`, {
    headers: authHeader(),
  });
};

export {
  getReviewList,
  getProvidersToApprove,
  getProviderAverages,
  getEvaluationList,
};
