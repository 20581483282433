import React from 'react';
import { Container, SlideArea } from './styles';
import FieldsButtons from '../../components/FieldsButtons';

export default function index() {
  return (
    <Container>
      <SlideArea>
        Aqui você encontra os melhores materiais e serviços para a sua obra e
        industria
      </SlideArea>
      <FieldsButtons />
    </Container>
  );
}
