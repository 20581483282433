import React from 'react';
import { Wrapper, Container } from './styles';
import Menu from '../../components/Menu';
import SearchResultList from '../../components/SearchResultList';
import { getProviderList } from '../../services/provider.service';

export default function index() {
  const [searchResultList, setSearchResultList] = React.useState();
  React.useEffect(async () => {
    const result = (await getProviderList()).data;
    setSearchResultList(result);
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <SearchResultList
            loading={!searchResultList}
            list={searchResultList}
          />
        </Wrapper>
      </Menu>
    </Container>
  );
}
