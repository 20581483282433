import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import theme from '../../helpers/theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 101px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
