import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: -25px;
  z-index: 2;
  width: 100%;
  padding-right: 50px;
`;

export const PageTitle = styled.div`
  background: ${theme.colors.color_2};
  color: white;
  ${theme.font.text};
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 20px;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.color_1};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 35px 45px 50px;
`;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: calc(100% - 250px);
`;
export const Title = styled.h2`
  ${theme.font.title};
  color: ${theme.colors.color_2};
  font-size: 24px;
  margin: 0;
`;

export const Button = styled.a`
  margin-left: 15px;
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;
