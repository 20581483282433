import React from 'react';
import {
  Container,
  Wrapper,
  Button,
  Content,
  Header,
  PageTitle,
  InnerWrapper,
} from './styles';
import Menu from '../../components/Menu';
import { getAdminReport } from '../../services/provider.service';

export default function index() {
  const [download, setDownload] = React.useState();

  React.useEffect(async () => {
    const report = await getAdminReport();

    const blob = new Blob([report.data], {
      type: 'application/pdf',
    });
    setDownload(window.URL.createObjectURL(blob));
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <Content>
            <Header>
              <PageTitle>Relatórios</PageTitle>
            </Header>
            <InnerWrapper>
              {download && (
                <Button href={download} download="report.pdf">
                  Relatório Administrativo
                </Button>
              )}
            </InnerWrapper>
          </Content>
        </Wrapper>
      </Menu>
    </Container>
  );
}
