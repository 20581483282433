import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 101px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/images/hexagon_background.png');
  background-position-x: -20%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  width: 100%;
  height: 100%;
`;

export const AereaLabel = styled.div`
  background-color: ${theme.colors.color_2};
  border-radius: 50px;
  ${theme.font.title};
  text-transform: uppercase;
  padding: 5px 20px;
  color: white;
  font-size: 24px;
  margin-bottom: 25px;
`;

export const Remember = styled(Link)`
  ${theme.font.text}
  color: ${theme.colors.color_3};
  text-decoration: none;
  font-size: 14px;
  transition: 0.3s;
  margin-top: 20px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;

export const ContactUs = styled(Link)`
  ${theme.font.text}
  color: ${theme.colors.color_3};
  text-decoration: none;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
  text-transform: uppercase;
  margin-top: 20px;
  transition: 0.3s;
`;
