const password_ValidationRules = (value) => {
  if (!value || value.trim() === '') return 'A senha é obrigatória';
  return null;
};

const confirm_password_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'A confirmação de senha é obrigatória';
  return null;
};

const validate = {
  password: password_ValidationRules,
  confirm_password: confirm_password_ValidationRules,
};
const formValidation = (values, errors, touched) =>
  Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
export { formValidation };
