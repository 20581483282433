import React from 'react';
import { connect } from 'react-redux';
import {
  Wrapper,
  Container,
  Background,
  Title,
  StatusBarRow,
  StatusBarWrapper,
  StatusBar,
  StatusBarLabel,
  ButtonWrapper,
  Button,
} from './styles';
import { logout } from '../../actions/auth';

function RegistrationNotCompleted(props) {
  React.useEffect(() => {
    setTimeout(() => {
      props.dispatch(logout());
    }, 100);
  }, []);

  return (
    <Container>
      <Wrapper>
        <Title>
          Falta pouco para você se tornar um fornecedor CIVIL! Acompanhe aqui o
          seu status.
        </Title>
        <StatusBarRow>
          <StatusBarWrapper>
            <StatusBar completed />
            <StatusBarLabel>Preenchimento de dados</StatusBarLabel>
          </StatusBarWrapper>
          <StatusBarWrapper>
            <StatusBar />
            <StatusBarLabel>Aprovação</StatusBarLabel>
          </StatusBarWrapper>
          <StatusBarWrapper>
            <StatusBar />
            <StatusBarLabel>Complete seu perfil</StatusBarLabel>
          </StatusBarWrapper>
        </StatusBarRow>
        <ButtonWrapper>
          <Button to="/contato">Fale Conosco</Button>
          <Button to="/contato">Duvidas?</Button>
        </ButtonWrapper>
      </Wrapper>

      <Background />
    </Container>
  );
}

export default connect()(RegistrationNotCompleted);
