/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Container, ItemsWrapper, Item, Input } from './styles';
import { history } from '../../../../helpers/history';

export default function Autocomplete(props) {
  const [activeSuggestion, setActiveSuggestion] = React.useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [userInput, setUserInput] = React.useState(undefined);
  const [inFocus, setInFocus] = React.useState(false);
  const [mouseOverList, setMouseOverList] = React.useState(false);

  const { suggestions } = props;

  const [
    suggestionsListComponent,
    setSuggestionsListComponent,
  ] = React.useState(<></>);

  const onChange = (e) => {
    const _userInput = e.currentTarget.value;

    const _filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(_userInput.toLowerCase()) > -1
    );

    setActiveSuggestion(0);
    setFilteredSuggestions(_filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
  };

  const onClick = (e, selected) => {
    history.push(`/pesquisa?q=${selected.name}&type=${selected.type}`);
    window.location.reload();
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
  };

  const onFocus = () => {
    setActiveSuggestion(0);
    setFilteredSuggestions(suggestions);
    setShowSuggestions(true);
    setInFocus(true);
  };

  const onBlur = () => {
    if (!userInput && !mouseOverList) {
      setActiveSuggestion(0);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
      setInFocus(false);
    }
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  React.useEffect(async () => {
    if (showSuggestions && (userInput || inFocus)) {
      if (filteredSuggestions.length) {
        setSuggestionsListComponent(
          <ItemsWrapper>
            {filteredSuggestions.map((suggestion, index) => {
              return (
                <Item
                  activeSuggestion={activeSuggestion}
                  key={index}
                  onClick={(e) => {
                    onClick(e, suggestion);
                  }}
                >
                  {suggestion.name}
                </Item>
              );
            })}
          </ItemsWrapper>
        );
      } else {
        setSuggestionsListComponent(
          <ItemsWrapper>
            <Item>Busca sem resultados</Item>
          </ItemsWrapper>
        );
      }
    } else {
      setSuggestionsListComponent(<></>);
    }
  }, [showSuggestions, userInput, filteredSuggestions, props.suggestions]);

  return (
    <>
      {!props.loading && (
        <Container>
          <Input
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            value={userInput}
            placeholder="Insira o nome do material/serviço que procura"
          />
          <div
            onMouseEnter={() => {
              setMouseOverList(true);
            }}
            onMouseLeave={() => {
              setMouseOverList(false);
            }}
          >
            {suggestionsListComponent}
          </div>
        </Container>
      )}
    </>
  );
}
