import React from 'react';
import { DateTime } from 'luxon';
import { history } from '../../helpers/history';
import ProgressCircularBar from '../ProgressCircularBar';
import ProgressBar from '../ProgressBar';
import { aproveProvider } from '../../services/provider.service';
import { getEngineerList } from '../../services/customer.service';
import { createContract } from '../../services/contracts.service';

import {
  Container,
  Header,
  Title,
  ProviderEvaluation,
  ProviderEvaluationAverage,
  ProviderEvaluationTotal,
  ProviderInfosWrapper,
  RowInfo,
  Label,
  Data,
  Body,
  BodyLeft,
  BodyRight,
  ResumeRowInfo,
  ResumeRowLabel,
  ResumeRowBar,
  AveragesWrapper,
  AveragesHeader,
  AveragesTitle,
  AveragesSubTitle,
  FooterWrapper,
  FooterTitle,
  CommentsList,
  CommentWrapper,
  CommentLeft,
  CommentRight,
  Avatar,
  CommentBox,
  CommentTitle,
  CommentDate,
  CommentContent,
  ButtonsWrapper,
  Button,
  ErrorWrapper,
  HeaderLeftSide,
  LabelForm,
  Select,
  CscWrapper,
  Input,
  ErrorLabel,
} from './styles';
import { formValidation } from './validation';

export default function ProviderResume(props) {
  const [loading, setLoading] = React.useState(false);
  const [customerList, setCustomerList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [values, setValues] = React.useState({
    customer: '',
    service: '',
    start_date: '',
    end_date: '',
  });
  const { provider } = props;

  React.useEffect(async () => {
    if (props.isCSC) {
      const customers = (await getEngineerList()).data;
      setCustomerList(customers);
    }
  }, [props]);

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const onAprove = async (evt, aprove) => {
    evt.preventDefault();
    setLoading(true);
    setError(null);
    await aproveProvider(provider.provider._id, aprove)
      .then(() => {
        setLoading(false);
        history.push('/dashboard');
        window.location.reload();
      })
      .catch(() => {
        setError('Ocorreu um error');
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onCreateContract = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);
    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) {
      const toSend = {
        ...values,
        provider: provider.provider._id,
      };
      await createContract(toSend)
        .then(() => {
          setLoading(false);
          history.push('/fornecedores');
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          setError(JSON.stringify(err.response.data));
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <Container>
      <Header>
        <HeaderLeftSide>
          <Title>{provider.provider.fantasy_name}</Title>
          {!props.toApprove && (
            <ProviderEvaluation>
              <ProviderEvaluationAverage>
                <ProgressCircularBar
                  progress={provider.valorMedium * 10}
                  size={35}
                  strokeWidth={2}
                  circleOneStroke="#c3c3c3"
                  circleTwoStroke="red"
                />
              </ProviderEvaluationAverage>
              <ProviderEvaluationTotal>
                - {provider.quantity_reviews} Avaliações
              </ProviderEvaluationTotal>
            </ProviderEvaluation>
          )}
        </HeaderLeftSide>
      </Header>
      <Body>
        <BodyLeft>
          <ProviderInfosWrapper>
            <RowInfo>
              <Label>Razão Social: </Label>
              <Data>{provider.provider.social_reason}</Data>
            </RowInfo>
            <RowInfo>
              <Label>Nome Fantasia: </Label>
              <Data>{provider.provider.fantasy_name}</Data>
            </RowInfo>
            <RowInfo>
              <Label>CNPJ: </Label>
              <Data>{provider.provider.cnpj}</Data>
            </RowInfo>
            {!props.toApprove && (
              <RowInfo>
                <Label> Apresentação da Empresa: </Label>
                <Data>
                  {provider.provider.presentation
                    ? provider.provider.presentation.company_presentation
                    : ''}
                </Data>
              </RowInfo>
            )}
            {!props.toApprove && (
              <RowInfo>
                <Label>Diferenciais: </Label>
                <Data>
                  {provider.provider.presentation
                    ? provider.provider.presentation.differentials
                    : ''}
                </Data>
              </RowInfo>
            )}
            {!props.toApprove && (
              <RowInfo>
                <Label>Informações adicionais: </Label>
                <Data>
                  {provider.provider.presentation
                    ? provider.provider.presentation.additional_information
                    : ''}
                </Data>
              </RowInfo>
            )}
            <RowInfo>
              <Label>Certifições e Selos: </Label>
              <Data>
                {!!provider.provider.seal.length &&
                  provider.provider.seal
                    .map((s) => `Nome: ${s.name}, Cod.: ${s.code}`)
                    .join(' | ')}
                {!provider.provider.seal.length && `Não`}
              </Data>
            </RowInfo>
          </ProviderInfosWrapper>
          <ProviderInfosWrapper>
            <RowInfo>
              <Label>Materiais: </Label>
              <Data>
                {provider.provider.material.map((m) => `${m}`).join(', ')}
              </Data>
            </RowInfo>
            <RowInfo>
              <Label>Serviços: </Label>
              <Data>
                {provider.provider.service.map((m) => `${m}`).join(', ')}
              </Data>
            </RowInfo>
          </ProviderInfosWrapper>
          {props.toApprove && (
            <ProviderInfosWrapper>
              <RowInfo>
                <Label>Responsável </Label>
                <Data>
                  {provider.provider.externalCustomer.map(
                    (ex) => `${ex.responsible}`
                  )}
                </Data>
              </RowInfo>
              <RowInfo>
                <Label>Email </Label>
                <Data>
                  {provider.provider.externalCustomer.map(
                    (ex) => `${ex.email}`
                  )}
                </Data>
              </RowInfo>
            </ProviderInfosWrapper>
          )}
          <ProviderInfosWrapper>
            <RowInfo>
              <Label>Telefone: </Label>
              <Data>
                {provider.provider.phones
                  .map((phone) => `${phone}`)
                  .join(' | ')}
              </Data>
            </RowInfo>
            {!props.toApprove && (
              <RowInfo>
                <Label>Site: </Label>
                <Data>
                  {provider.provider.socialNetwork
                    ? provider.provider.socialNetwork.website
                    : ''}
                </Data>
              </RowInfo>
            )}
            <RowInfo>
              <Label>E-mail: </Label>
              <Data>
                {provider.provider.externalCustomer.map((ex) => `${ex.email}`)}
              </Data>
            </RowInfo>
            <RowInfo>
              <Label>Endereço: </Label>
              <Data>
                {provider.provider.address_street},{' '}
                {provider.provider.city.description},{' '}
                {provider.provider.state.description} -{' '}
                {provider.provider.address_postalCode}
              </Data>
            </RowInfo>
          </ProviderInfosWrapper>
          <ProviderInfosWrapper>
            {!props.toApprove && (
              <>
                <RowInfo>
                  <Label>Redes Sociais </Label>
                </RowInfo>
                <RowInfo>
                  <Label>Facebook: </Label>
                  <Data>
                    {provider.provider.socialNetwork
                      ? provider.provider.socialNetwork.facebook
                      : ''}
                  </Data>
                </RowInfo>
                <RowInfo>
                  <Label>Instagram: </Label>
                  <Data>
                    {provider.provider.socialNetwork
                      ? provider.provider.socialNetwork.instagram
                      : ''}
                  </Data>
                </RowInfo>
                <RowInfo>
                  <Label>Linkedin: </Label>
                  <Data>
                    {provider.provider.socialNetwork
                      ? provider.provider.socialNetwork.linkedin
                      : ''}
                  </Data>
                </RowInfo>
              </>
            )}
          </ProviderInfosWrapper>
          <ProviderInfosWrapper>
            {!props.toApprove && (
              <>
                <RowInfo>
                  <Label>Serviços prestados anteriormente à Civil: </Label>
                  <Data>
                    {provider.provider.renderedService
                      ? provider.provider.renderedService
                      : ''}
                  </Data>
                </RowInfo>
              </>
            )}
          </ProviderInfosWrapper>
        </BodyLeft>
        <BodyRight>
          <>
            {customerList && props.isCSC && (
              <CscWrapper>
                <LabelForm>
                  <LabelForm>
                    Eng. Responsável:
                    <Select
                      id="customer"
                      name="customer"
                      value={values.customer}
                      onChange={handleChange}
                      required
                    >
                      <option selected>Selecione</option>
                      {customerList &&
                        customerList.map((_customer, index) => (
                          <option key={index} value={_customer._id}>
                            {_customer.user.name} - {_customer.company}
                          </option>
                        ))}
                    </Select>
                    <ErrorLabel show={touched.customer && errors.customer}>
                      {errors.customer}
                    </ErrorLabel>
                  </LabelForm>
                  <LabelForm>
                    Serviço/Material:
                    <Select
                      id="service"
                      name="service"
                      value={values.service}
                      onChange={handleChange}
                      required
                    >
                      <option selected>Selecione</option>
                      {provider.provider.material &&
                        provider.provider.service &&
                        provider.provider.material &&
                        [
                          ...provider.provider.material,
                          ...provider.provider.service,
                        ].map((_service, index) => (
                          <option key={index} value={_service}>
                            {_service}
                          </option>
                        ))}
                    </Select>
                    <ErrorLabel show={touched.service && errors.service}>
                      {errors.service}
                    </ErrorLabel>
                  </LabelForm>
                  <LabelForm>
                    Inicio de contrato (dd/mm/aaaa):
                    <Input
                      id="start_date"
                      type="text"
                      name="start_date"
                      value={values.start_date}
                      onChange={handleChange}
                    />
                    <ErrorLabel show={touched.start_date && errors.start_date}>
                      {errors.start_date}
                    </ErrorLabel>
                  </LabelForm>
                  <LabelForm>
                    Fim de contrato (dd/mm/aaaa):
                    <Input
                      id="end_date"
                      type="text"
                      name="end_date"
                      value={values.end_date}
                      onChange={handleChange}
                    />
                    <ErrorLabel show={touched.end_date && errors.end_date}>
                      {errors.end_date}
                    </ErrorLabel>
                  </LabelForm>
                </LabelForm>

                <Button
                  type="submit"
                  onClick={onCreateContract}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                  {!loading && (
                    <>
                      Iniciar <br />
                      Contrato
                    </>
                  )}
                </Button>
              </CscWrapper>
            )}
          </>
          {!props.toApprove && (
            <>
              <AveragesHeader>
                <AveragesTitle>Resumo das Avaliações</AveragesTitle>
                <AveragesSubTitle>{provider.valorMedium || 0}</AveragesSubTitle>
              </AveragesHeader>
              <AveragesWrapper>
                <ResumeRowInfo>
                  <ResumeRowLabel>Prazo</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar completed={provider.medium_deadline * 10} />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Documentação</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar
                      completed={provider.medium_documentation * 10}
                    />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Organização</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar
                      completed={provider.medium_organization * 10}
                    />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Preço</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar completed={provider.medium_price * 10} />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Qualidade</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar completed={provider.medium_quality * 10} />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Relacionamento</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar
                      completed={provider.medium_relationship * 10}
                    />
                  </ResumeRowBar>
                </ResumeRowInfo>
                <ResumeRowInfo>
                  <ResumeRowLabel>Segurança</ResumeRowLabel>
                  <ResumeRowBar>
                    <ProgressBar completed={provider.medium_security * 10} />
                  </ResumeRowBar>
                </ResumeRowInfo>
              </AveragesWrapper>
            </>
          )}
        </BodyRight>
      </Body>
      {props.toApprove && (
        <ButtonsWrapper>
          <Button
            type="button"
            disabled={loading}
            onClick={(evt) => {
              onAprove(evt, true);
            }}
          >
            {loading && <span className="spinner-border spinner-border-sm" />}
            {!loading && <>Aprovado</>}
          </Button>
          <Button
            type="button"
            disabled={loading}
            onClick={(evt) => {
              onAprove(evt, false);
            }}
          >
            {loading && <span className="spinner-border spinner-border-sm" />}
            {!loading && <>Reprovado</>}
          </Button>
        </ButtonsWrapper>
      )}
      {error && <ErrorWrapper>{error}</ErrorWrapper>}

      {!props.toApprove && !!provider.reviews_provider.length && (
        <FooterWrapper>
          <FooterTitle>Comentários sobre o Fornecedor</FooterTitle>
          <CommentsList>
            {provider.reviews_provider.map((comment, index) => (
              <CommentWrapper key={index}>
                <CommentLeft>
                  <Avatar />
                </CommentLeft>
                <CommentRight>
                  <CommentBox>
                    <CommentTitle>
                      {comment.customer.user.name} publicou uma avaliação
                    </CommentTitle>
                    <CommentDate>
                      {DateTime.fromISO(comment.createdAt).toFormat(
                        "dd 'de' LLL"
                      )}
                    </CommentDate>
                    <CommentContent>{comment.experience}</CommentContent>
                  </CommentBox>
                </CommentRight>
              </CommentWrapper>
            ))}
          </CommentsList>
        </FooterWrapper>
      )}
    </Container>
  );
}
