import styled from 'styled-components';
import theme from '../../../../helpers/theme';

export const Container = styled.div``;

export const SubtitlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Subtitle = styled.div`
  ${theme.font.text};
  color: ${theme.colors.color_3};
  font-size: 10px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: inline-flex;
`;
