import React from 'react';
import { Container } from './styles';
import PureMenu from './components/PureMenu';
import { getMenu } from '../../services/menu.service';

export default function MenuComponent(props) {
  const [menuList, setMenuList] = React.useState();

  React.useEffect(async () => {
    const menu = await getMenu();
    setMenuList(menu.data);
  }, []);

  return (
    <Container>
      <PureMenu loading={!menuList} list={menuList} />
      {props.children && props.children}
    </Container>
  );
}
