import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const SlideArea = styled.div`
  width: 100%;
  height: 600px;
  background: linear-gradient(rgb(0 0 0 / 70%), rgb(0 0 0 / 50%)),
    url('/images/homecover.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  ${theme.font.title};
  text-transform: uppercase;
  color: white;
  padding-right: 50%;
  padding-left: 10%;
  line-height: 50px;
`;
