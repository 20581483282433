import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div`
  width: 80%;
  margin: 40px auto 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Button = styled(Link)`
  border-radius: 40px;
  border: 2px solid ${theme.colors.color_5};
  ${theme.font.title}
  color: ${theme.colors.color_2};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px 25px;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;

export const ButtonBoxInfo = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  ${theme.font.normal}
  color: ${theme.colors.color_4};
`;

export const Register = styled(Link)`
  ${theme.font.normal}
  color: ${theme.colors.color_4};
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;
