import React from 'react';
import {
  Container,
  Background,
  Wrapper,
  Header,
  Title,
  Body,
  Card,
  CardHeader,
  CardBody,
  Footer,
  FooterWrapper,
  FooterHeader,
  InputsWrapper,
  Input,
  RowInput,
  Textarea,
  Button,
  AddressMap,
} from './styles';

import { formValidation } from './validation';
import UserService from '../../services/user.service';

export default function index() {
  const [loading, setLoading] = React.useState(false);
  const [sended, setSended] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    email: '',
    name: '',
    text: '',
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) {
      UserService.sendContactEmail(values)
        .then((response) => {
          setSended(true);
          setLoading(false);
          return response;
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Container>
      <Background />
      <Wrapper>
        <Header>
          <Title>Contato</Title>
        </Header>
        <Body>
          <Card>
            <CardHeader>Telefone</CardHeader>
            <CardBody>
              Civil Construtora: (71) 2104-5366 <br />
              Pedreira Civil: (71) 3215-8080 <br />
              Pré Moldados: (71) 3594-9898
            </CardBody>
          </Card>
          <Card>
            <CardHeader>Email</CardHeader>
            <CardBody>qualidade@civil.com.br</CardBody>
          </Card>
          <Card>
            <CardHeader>Endereço</CardHeader>
            <CardBody small left>
              <AddressMap
                target="blank"
                href="https://www.google.com.br/maps/place/Civil/@-12.8435712,-38.4268446,17z/data=!3m1!4b1!4m5!3m4!1s0x71613bfa0dc0bb3:0x27b9f5beccfc7fbf!8m2!3d-12.8435764!4d-38.4246559"
              >
                <b>Endereço Pré-Moldados:</b>
                <div>
                  Via Ipitanga, S/N, Cia Sul - Centro Industrial de Aratu.
                </div>
                <div>CEP: 43.700-971 - Simões Filho/BA</div>
                <br />
              </AddressMap>
              <AddressMap
                target="blank"
                href="https://www.google.com.br/maps/place/CIVIL+Pedreira/@-12.8777152,-38.4448926,17z/data=!3m1!4b1!4m5!3m4!1s0x716119dc92d5251:0x531657fcd095f078!8m2!3d-12.8777204!4d-38.4427039"
              >
                <b>Endereço Pedreira:</b>
                <div>Rodovia Base Naval de Aratu, Km 1,5, Pirajá</div>
                <div>CEP: 40.810-010 - Salvador/BA</div>
                <br />
              </AddressMap>
              <AddressMap
                target="blank"
                href="https://www.google.com.br/maps/place/Grupo+Civil/@-12.9892243,-38.4509509,17z/data=!4m14!1m8!3m7!1s0x7161b0c04f2a649:0x6549d83fce068ec2!2sRua+Arthur+de+Azev%C3%AAdo+Machado,+1225+-+Stiep,+Salvador+-+BA,+41760-000!3b1!8m2!3d-12.9892295!4d-38.4487622!10e3!3m4!1s0x7161b74aadce085:0x4b52f0688a6e464b!8m2!3d-12.989245!4d-38.4486364"
              >
                <b>Endereço Construtora:</b>
                <div>
                  Rua Arthur de Azevêdo Machado, 1225 - Stiep, Salvador - BA,
                  41770-790
                </div>
                <br />
              </AddressMap>
            </CardBody>
          </Card>
        </Body>
        <Footer onSubmit={handleSubmit} autoComplete="off">
          <FooterWrapper>
            <FooterHeader>Envie-nos uma mensagem</FooterHeader>
            <InputsWrapper>
              <RowInput>
                <Input
                  id="name"
                  type="name"
                  name="name"
                  placeholder="Seu nome"
                  value={values.name}
                  onChange={handleChange}
                  required
                />
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Seu e-mail"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
              </RowInput>
              <Textarea
                id="text"
                type="text"
                name="text"
                placeholder="Sua mensagem"
                value={values.text}
                onChange={handleChange}
                rows="4"
              />
            </InputsWrapper>
            <Button disabled={loading || sended} type="submit">
              {loading && <span className="spinner-border spinner-border-sm" />}
              {!loading && !sended && <>Enviar</>}
              {sended && <>Enviado com Sucesso!</>}
            </Button>
          </FooterWrapper>
        </Footer>
      </Wrapper>
    </Container>
  );
}
