import React from 'react';
import {
  Wrapper,
  Container,
  Background,
  ListWrapper,
  BodyWrapper,
  AveragesWrapper,
  ResumeRowInfo,
  AveragesContainer,
  AveragesHeader,
  AveragesTitle,
  AveragesSubTitle,
  ResumeRowLabel,
  ResumeRowBar,
} from './styles';
import ProgressBar from '../../components/ProgressBar';
import Menu from '../../components/Menu';
import UserPresentation from '../../components/UserPresentation';
import DashboardLastList from '../../components/DashboardLastList';
import {
  getReviewList,
  getProvidersToApprove,
  getProviderAverages,
  getEvaluationList,
} from '../../services/dashboard.service';
import {
  checkIfIsAdmin,
  checkIfIsProvider,
  checkIfIsCustomer,
} from '../../helpers/checkUserType';

export default function index() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [reviewList, setReviewList] = React.useState();
  const [averages, setAverages] = React.useState(null);

  React.useEffect(async () => {
    let reviews = null;

    if (user.role.find((r) => r === 'admin')) {
      reviews = (await getProvidersToApprove()).data.provider.docs;
    }

    if (user.role.find((r) => r === 'client')) {
      const evaluationList = (await getEvaluationList()).data;
      reviews = evaluationList.docs;
      setAverages({
        medium_deadline: evaluationList.medium_deadline,
        medium_documentation: evaluationList.medium_documentation,
        medium_organization: evaluationList.medium_organization,
        medium_payment: evaluationList.medium_payment,
        medium_relationship: evaluationList.medium_relationship,
        valorMedium: evaluationList.valorMedium,
      });
    }

    if (user.role.find((r) => r === 'provider')) {
      reviews = (await getReviewList()).data.docs;
      const _averages = await getProviderAverages();
      setAverages(_averages.data);
    }

    setReviewList(reviews);
  }, []);

  const getDescription = (userRoles) => {
    if (userRoles.find((r) => r === 'provider')) {
      return 'Mantenha sua conta atualizada para fechar contratos';
    }
    if (userRoles.find((r) => r === 'client')) {
      return 'Gerencie sua conta, acompanhe novos fornecedores e mande mensagens para os colaboradores.';
    }
    return '';
  };
  return (
    <Container>
      <Menu>
        <Wrapper>
          <UserPresentation
            name={user.name}
            description={getDescription(user.role)}
          />
          <BodyWrapper>
            <ListWrapper>
              <DashboardLastList
                loading={!reviewList}
                list={reviewList}
                isAdmin={checkIfIsAdmin(user.role)}
                isCustomer={checkIfIsCustomer(user.role)}
                isProvider={checkIfIsProvider(user.role)}
              />
            </ListWrapper>
            {averages && (
              <AveragesContainer>
                <AveragesHeader>
                  <AveragesTitle>Resumo das Avaliações</AveragesTitle>
                  <AveragesSubTitle>
                    {averages.valorMedium || 0}
                  </AveragesSubTitle>
                </AveragesHeader>
                <AveragesWrapper>
                  <ResumeRowInfo>
                    <ResumeRowLabel>Prazo</ResumeRowLabel>
                    <ResumeRowBar>
                      <ProgressBar completed={averages.medium_deadline * 10} />
                    </ResumeRowBar>
                  </ResumeRowInfo>
                  <ResumeRowInfo>
                    <ResumeRowLabel>Documentação</ResumeRowLabel>
                    <ResumeRowBar>
                      <ProgressBar
                        completed={averages.medium_documentation * 10}
                      />
                    </ResumeRowBar>
                  </ResumeRowInfo>
                  <ResumeRowInfo>
                    <ResumeRowLabel>Organização</ResumeRowLabel>
                    <ResumeRowBar>
                      <ProgressBar
                        completed={averages.medium_organization * 10}
                      />
                    </ResumeRowBar>
                  </ResumeRowInfo>
                  {averages.medium_price && (
                    <ResumeRowInfo>
                      <ResumeRowLabel>Preço</ResumeRowLabel>
                      <ResumeRowBar>
                        <ProgressBar completed={averages.medium_price * 10} />
                      </ResumeRowBar>
                    </ResumeRowInfo>
                  )}
                  {averages.medium_quality && (
                    <ResumeRowInfo>
                      <ResumeRowLabel>Qualidade</ResumeRowLabel>
                      <ResumeRowBar>
                        <ProgressBar completed={averages.medium_quality * 10} />
                      </ResumeRowBar>
                    </ResumeRowInfo>
                  )}
                  {averages.medium_payment && (
                    <ResumeRowInfo>
                      <ResumeRowLabel>Pagamento</ResumeRowLabel>
                      <ResumeRowBar>
                        <ProgressBar completed={averages.medium_payment * 10} />
                      </ResumeRowBar>
                    </ResumeRowInfo>
                  )}
                  <ResumeRowInfo>
                    <ResumeRowLabel>Relacionamento</ResumeRowLabel>
                    <ResumeRowBar>
                      <ProgressBar
                        completed={averages.medium_relationship * 10}
                      />
                    </ResumeRowBar>
                  </ResumeRowInfo>
                  {averages.medium_security && (
                    <ResumeRowInfo>
                      <ResumeRowLabel>Segurança</ResumeRowLabel>
                      <ResumeRowBar>
                        <ProgressBar
                          completed={averages.medium_security * 10}
                        />
                      </ResumeRowBar>
                    </ResumeRowInfo>
                  )}
                </AveragesWrapper>
              </AveragesContainer>
            )}
          </BodyWrapper>
        </Wrapper>
      </Menu>
      <Background />
    </Container>
  );
}
