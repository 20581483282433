import axios from 'axios';
import authHeader from './auth-header';

const createContract = (body) => {
  return axios.post(`${process.env.REACT_APP_API_URL}contracts`, body, {
    headers: authHeader(),
  });
};

export { createContract };
