import React from 'react';
import { history } from '../../helpers/history';

import {
  Container,
  Form,
  Label,
  ButtonBox,
  Button,
  ErrorLabel,
  Header,
  Title,
  FlexRow,
  Select,
  InputDate,
} from './styles';

import { formValidation } from './validation';
import { createContract } from '../../services/contracts.service';

export default function RegisterForm(props) {
  const [errors, setErrors] = React.useState({});
  const [submitErrors, setSubmitErrors] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    enginner: '',
    provider: '',
    start_date: '',
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) {
      const toSend = {
        customer: values.enginner,
        start_date: values.start_date,
        provider: values.provider,
      };
      await createContract(toSend)
        .then(() => {
          history.push('/criar-contrato');
          window.location.reload();
        })
        .catch((err) => {
          setSubmitErrors(`Error: ${JSON.stringify(err.response.data.error)}`);
        });
    }
  };
  return (
    <Container>
      <Header>
        <Title>Criar novo Contrato</Title>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <FlexRow>
          <Label htmlFor="provider">
            Fornecedor
            <Select
              id="provider"
              name="provider"
              value={values.provider}
              onChange={handleChange}
            >
              <option selected>Selecione</option>
              {props.providerList &&
                props.providerList.map((_provider, index) => (
                  <option key={index} value={_provider._id}>
                    {_provider.cnpj} - {_provider.fantasy_name}
                  </option>
                ))}
            </Select>
            <ErrorLabel show={touched._provider && errors._provider}>
              {errors._provider}
            </ErrorLabel>
          </Label>
          <Label htmlFor="enginner">
            Engenheiro Responsável
            <Select
              id="enginner"
              name="enginner"
              value={values.enginner}
              onChange={handleChange}
            >
              <option selected>Selecione</option>
              {props.engineerList &&
                props.engineerList.map((_enginner, index) => (
                  <option key={index} value={_enginner._id}>
                    {_enginner.user.name}
                  </option>
                ))}
            </Select>
            <ErrorLabel show={touched.enginner && errors.enginner}>
              {errors.enginner}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="start_date">
            Data de entrada da nota
            <InputDate
              mask="99/99/9999"
              id="start_date"
              type="text"
              name="start_date"
              value={values.start_date}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.start_date && errors.start_date}>
              {errors.start_date}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <ButtonBox>
          <ErrorLabel show={submitErrors}>{submitErrors}</ErrorLabel>
          <Button disabled={props.loading} type="submit">
            {props.loading && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {!props.loading && <>Criar Contrato</>}
          </Button>
        </ButtonBox>
      </Form>
    </Container>
  );
}
