import React from 'react';
import { connect } from 'react-redux';
import { Container, Wrapper } from './styles';
import CreateCustomerForm from '../../components/CreateCustomerForm';
import { getStates } from '../../services/states.service';
import { getSearchList } from '../../services/search.service';
import Menu from '../../components/Menu';

const Register = () => {
  const [states, setStates] = React.useState();
  const [materialAndServiceList, setMaterialAndServiceList] = React.useState();

  React.useEffect(async () => {
    const statesData = await getStates();
    const materialAndServiceData = await getSearchList();
    setMaterialAndServiceList(materialAndServiceData.data);
    setStates(statesData.data);
  }, []);
  return (
    <Container>
      <Menu>
        <Wrapper>
          <CreateCustomerForm
            states={states}
            materialAndService={materialAndServiceList}
          />
        </Wrapper>
      </Menu>
    </Container>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Register);
