import React from 'react';
import {
  Container,
  Label,
  Input,
  ButtonBox,
  Button,
  ErrorLabel,
} from './styles';
import { history } from '../../helpers/history';

import { formValidation } from './validation';

export default function SetNewPasswordForm(props) {
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [touched, setTouched] = React.useState({});
  const [emailSent, setEmailSent] = React.useState(false);
  const [urltoRedirect, setUrltoRedirect] = React.useState('');
  const [values, setValues] = React.useState({
    password: undefined,
    confirm_password: undefined,
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid && values.password === values.confirm_password) {
      await props
        .setNewPwd(props.token, props.user_type, { password: values.password })
        .then(() => {
          setLoading(false);
          setEmailSent(true);
          setUrltoRedirect(
            `/login?area=${
              props.user_type === 'csc' ? 'admin' : props.user_type
            }`
          );
        })
        .catch((err) => {
          setLoading(false);
          setErrors({
            confirm_password: `Error: ${err.response.data.message}`,
          });
          setTouched({ confirm_password: true });
        });
    } else if (values.password !== values.confirm_password) {
      setErrors({
        confirm_password: 'Senha e confirmação de senha devem ser iguais.',
        password: 'Senha e confirmação de senha devem ser iguais.',
      });
      setTouched({ confirm_password: true, password: true });
    }
  };

  return (
    <Container onSubmit={handleSubmit} autoComplete="off">
      {!emailSent && (
        <>
          <Label htmlFor="password">
            Nova Senha
            <Input
              id="password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.password && errors.password}>
              {errors.password}
            </ErrorLabel>
          </Label>

          <Label htmlFor="password">
            Repita a Senha
            <Input
              id="confirm_password"
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              required
            />
            <ErrorLabel
              show={touched.confirm_password && errors.confirm_password}
            >
              {errors.confirm_password}
            </ErrorLabel>
          </Label>

          <ButtonBox>
            <ErrorLabel show={props.errorMessage}>
              {props.errorMessage}
            </ErrorLabel>
            <Button disabled={loading} type="submit">
              {loading && <span className="spinner-border spinner-border-sm" />}
              {!loading && <>Enviar</>}
            </Button>
          </ButtonBox>
        </>
      )}
      {emailSent && (
        <center>
          Senha alterada com sucesso
          <Button
            onClick={() => {
              history.push(urltoRedirect);
              window.location.reload();
            }}
            type="button"
          >
            Fazer Login
          </Button>
        </center>
      )}
    </Container>
  );
}
