import React from 'react';
import { history } from '../../helpers/history';

import {
  Container,
  Form,
  Label,
  Input,
  InputCEP,
  ButtonBox,
  Button,
  ErrorLabel,
  Header,
  Title,
  FlexRow,
  Select,
  InputPhone,
} from './styles';

import { formValidation } from './validation';
import { createCustomer } from '../../services/customer.service';

export default function RegisterForm(props) {
  const [errors, setErrors] = React.useState({});
  const [submitErrors, setSubmitErrors] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    permission: 'customer',
    password: '',
    confirm_password: '',
    cpf: '',
    company: '',
    office: '',
    address_street: '',
    address_postalCode: '',
    phone_1: '',
    phone_2: '',
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid && values.password === values.confirm_password) {
      const permissionToSend = ['client'];
      if (values.permission !== 'client')
        permissionToSend.push(values.permission);
      const toSend = {
        user: {
          name: values.name,
          email: values.email,
          permission: permissionToSend,
        },
        password: values.password,
        cpf: values.cpf,
        address_street: values.address_street,
        address_postalCode: values.address_postalCode,
        phones: [values.phone_1, values.phone_2],
        company: values.company,
        office: values.office,
      };
      const { country } = props.states.shift();
      await createCustomer(country, values.state, values.city, toSend)
        .then(() => {
          history.push('/criar-usuario');
          window.location.reload();
        })
        .catch((err) => {
          setSubmitErrors(JSON.stringify(err.response.data.error));
        });
    } else if (values.password !== values.confirm_password) {
      setErrors({
        confirm_password: 'Senha e confirmação de senha devem ser iguais.',
        password: 'Senha e confirmação de senha devem ser iguais.',
      });
      setTouched({ confirm_password: true, password: true });
    }
  };
  return (
    <Container>
      <Header>
        <Title>Criar novo usuário</Title>
      </Header>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <FlexRow>
          <Label htmlFor="name">
            Nome
            <Input
              id="name"
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.name && errors.name}>
              {errors.name}
            </ErrorLabel>
          </Label>
          <Label htmlFor="cpf">
            Matricula/CNPJ
            <Input
              id="cpf"
              type="tel"
              name="cpf"
              value={values.cpf}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.cpf && errors.cpf}>
              {errors.cpf}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="email">
            Email
            <Input
              id="email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.email && errors.email}>
              {errors.email}
            </ErrorLabel>
          </Label>
          <Label htmlFor="permission">
            Permissão
            <Select
              id="permission"
              name="permission"
              value={values.permission}
              onChange={handleChange}
              required
            >
              <option selected>Selecione</option>
              <option value="client">Civil</option>
              <option value="engineer">Engenheiro</option>
              <option value="csc">CSC</option>
              <option value="admin">Admin</option>
            </Select>
            <ErrorLabel show={touched.permission && errors.permission}>
              {errors.permission}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="company">
            Empresa
            <Input
              id="company"
              type="text"
              name="company"
              value={values.company}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.company && errors.company}>
              {errors.company}
            </ErrorLabel>
          </Label>
          <Label htmlFor="office">
            Cargo
            <Input
              id="office"
              type="text"
              name="office"
              value={values.office}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.office && errors.office}>
              {errors.office}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="state">
            Estado
            <Select
              id="state"
              name="state"
              value={values.state}
              onChange={handleChange}
            >
              <option selected>Selecione</option>
              {props.states &&
                props.states.map((_state, index) => (
                  <option key={index} value={_state._id}>
                    {_state.description}
                  </option>
                ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
          <Label htmlFor="city">
            Cidade
            <Select
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              disabled={!values.state}
            >
              <option selected>Selecione</option>
              {values.state &&
                props.states
                  .find((state) => state._id === values.state)
                  .cities.map((_city, index) => (
                    <option key={index} value={_city._id}>
                      {_city.description}
                    </option>
                  ))}
            </Select>
            <ErrorLabel show={touched.social_reason && errors.social_reason}>
              {errors.social_reason}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="address_street">
            Endereço
            <Input
              id="address_street"
              type="text"
              name="address_street"
              value={values.address_street}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.address_street && errors.address_street}>
              {errors.address_street}
            </ErrorLabel>
          </Label>
          <Label htmlFor="address_postalCode">
            CEP
            <InputCEP
              id="address_postalCode"
              type="tel"
              name="address_postalCode"
              value={values.address_postalCode}
              onChange={(_value) => {
                handleChange({
                  target: {
                    name: 'address_postalCode',
                    value: _value,
                  },
                });
              }}
            />
            <ErrorLabel
              show={touched.address_postalCode && errors.address_postalCode}
            >
              {errors.address_postalCode}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <FlexRow>
          <Label htmlFor="phone_1">
            Telefone 1
            <InputPhone
              mask={
                values.phone_2.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_1"
              type="tel"
              name="phone_1"
              value={values.phone_1}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.phone_1 && errors.phone_1}>
              {errors.phone_1}
            </ErrorLabel>
          </Label>
          <Label htmlFor="phone_2">
            Telefone 2
            <InputPhone
              mask={
                values.phone_2.length > 14
                  ? '(99) 99999-9999'
                  : '(99) 9999-99999'
              }
              maskPlaceholder={null}
              id="phone_2"
              type="text"
              name="phone_2"
              value={values.phone_2}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.phone_2 && errors.phone_2}>
              {errors.phone_2}
            </ErrorLabel>
          </Label>
        </FlexRow>
        <FlexRow>
          <Label htmlFor="password">
            Senha:
            <Input
              id="password"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.password && errors.password}>
              {errors.password}
            </ErrorLabel>
          </Label>
          <Label htmlFor="confirm_password">
            Confirmação da Senha:
            <Input
              id="confirm_password"
              type="password"
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              required
            />
            <ErrorLabel
              show={touched.confirm_password && errors.confirm_password}
            >
              {errors.confirm_password}
            </ErrorLabel>
          </Label>
        </FlexRow>

        <ButtonBox>
          <ErrorLabel show={submitErrors}>{submitErrors}</ErrorLabel>
          <Button disabled={props.loading} type="submit">
            {props.loading && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {!props.loading && <>Cadastrar</>}
          </Button>
        </ButtonBox>
      </Form>
    </Container>
  );
}
