import React from 'react';
import { Container, HeaderMenu, FooterMenu, Item, Loading } from './styles';

export default function FieldsButtonsComponent(props) {
  return (
    <Container>
      {props.loading && <Loading className="spinner-border" />}
      {!props.loading && (
        <>
          <HeaderMenu>
            {props.list &&
              props.list.map((menuItem, index) => (
                <Item key={index} to={menuItem.path}>
                  {menuItem.label}
                </Item>
              ))}
          </HeaderMenu>
          <FooterMenu>
            <Item center="true" to="/logout">
              SAIR
            </Item>
          </FooterMenu>
        </>
      )}
    </Container>
  );
}
