import React from 'react';
import { Container, Wrapper, SubtitlesWrapper, Subtitle } from './styles';
import ScoreButton from '../ScoreButton';

const NPSRule = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export default function NPSSelect(props) {
  const [hoveredScore, setHoveredScore] = React.useState(-1);
  const [selectedScore, setSelectedScore] = React.useState(-1);

  const onMouseEnter = (buttonScore, setState) => () => {
    if (selectedScore === -1) setState(buttonScore);
  };

  const onSubmit = (buttonScore, setState) => (evt) => {
    evt.preventDefault();
    setState(buttonScore);
    setSelectedScore(buttonScore);
    props.score(buttonScore);
  };

  return (
    <Container>
      <SubtitlesWrapper>
        <Subtitle>Não Recomendaria</Subtitle>
        <Subtitle>Recomendaria</Subtitle>
      </SubtitlesWrapper>
      <Wrapper>
        {NPSRule.map((score) => (
          <ScoreButton
            buttonScore={score}
            hoveredScore={hoveredScore}
            key={score}
            onMouseEnter={onMouseEnter}
            onSubmit={onSubmit}
            setState={setHoveredScore}
          />
        ))}
      </Wrapper>
    </Container>
  );
}
