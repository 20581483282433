import React from 'react';
import { Container, Filler, Label } from './styles';

const ProgressBar = (props) => {
  const { completed } = props;

  return (
    <Container>
      <Filler completed={completed}>
        <Label>{`${completed / 10}`}</Label>
      </Filler>
    </Container>
  );
};

export default ProgressBar;
