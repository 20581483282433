import React from 'react';
import {
  Container,
  Left,
  Avatar,
  Right,
  UserTitle,
  UserDescription,
} from './styles';

export default function UserPresentation(props) {
  return (
    <Container>
      <Left>
        <Avatar />
      </Left>
      <Right>
        <UserTitle>Olá, {props.name && props.name}</UserTitle>
        <UserDescription>
          {props.description && props.description}
        </UserDescription>
      </Right>
    </Container>
  );
}
