import React from 'react';
import { connect } from 'react-redux';
import LoginBoxComponent from '../../components/LoginBox';
import { login } from '../../actions/auth';
import {
  Container,
  Background,
  AereaLabel,
  Remember,
  ContactUs,
} from './styles';
import useQuery from '../../helpers/useQuery';

const Login = (props) => {
  const [loading, toggleLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const query = useQuery();
  const onLogin = (loginData) => {
    toggleLoading(true);
    setError(false);

    const { email, password } = loginData;
    const { dispatch, history } = props;

    dispatch(login(email, password, query.get('area')))
      .then(() => {
        history.push('/dashboard');
        window.location.reload();
      })
      .catch((err) => {
        setError(err);
        toggleLoading(false);
      });
  };

  const getLabel = (area) => {
    if (area === 'provider') return 'Fornecedor';
    if (area === 'admin') return 'Administrador';
    return 'Usuário';
  };

  return (
    <Container>
      <Background />
      <AereaLabel>Área do {getLabel(query.get('area'))}</AereaLabel>
      <LoginBoxComponent
        errorMessage={error}
        onLogin={onLogin}
        loading={loading}
        showRegisterLink={query.get('area') === 'provider'}
      />
      <Remember to="/esqueci-minha-senha">Esqueci minha senha</Remember>
      <ContactUs to="/contato">Problemas? Fale conosco</ContactUs>
    </Container>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);
