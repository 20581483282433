import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div`
  height: 25px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 7px;
  /* margin: 50px; */
`;

export const Filler = styled.div`
  height: 100%;
  width: ${(props) => props.completed}%;
  background-color: ${theme.colors.color_2};
  transition: width 1s ease-in-out;
  border-radius: inherit;
  text-align: right;
  display: flex;
`;

export const Label = styled.div`
  margin-left: 10px;
  color: white;
  ${theme.font.title}
`;
