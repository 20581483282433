const emailValidationRules = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'O email é obrigatório';
  }
  return 'Por favor digite um email válido';
};
const passwordValidationRules = (password) => {
  if (password.trim() === '') return 'A senha é obrigatória';
  return null;
};

const social_reason_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Razão Social é obrigatório';
  return null;
};
const fantasy_name_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Nome Fantasia é obrigatório';
  return null;
};
const cnpj_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo CPNJ é obrigatório';
  return null;
};
const state_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Estado é obrigatório';
  return null;
};
const city_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Cidade é obrigatório';
  return null;
};
const address_street_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Endereço é obrigatório';
  return null;
};
const address_postalCode_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo CEP é obrigatório';
  return null;
};
const phone_1_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Telefone 1 é obrigatório';
  return null;
};
const phone_2_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Telefone 2 é obrigatório';
  return null;
};
const confirm_password_ValidationRules = (value) => {
  if (value.trim() === '') return 'A confirmação de senha é obrigatória';
  return null;
};
const terms_ValidationRules = (value) => {
  if (!value) return 'Os termos devem ser aceitos para continuar';
  return null;
};
const name_ValidationRules = (value) => {
  if (!value) return 'O campo Nome é obrigatório';
  return null;
};

const NoValidate = () => null;

const validate = {
  email: emailValidationRules,
  social_reason: social_reason_ValidationRules,
  fantasy_name: fantasy_name_ValidationRules,
  cnpj: cnpj_ValidationRules,
  name: name_ValidationRules,
  state: state_ValidationRules,
  city: city_ValidationRules,
  address_street: address_street_ValidationRules,
  address_postalCode: address_postalCode_ValidationRules,
  phone_1: phone_1_ValidationRules,
  phone_2: phone_2_ValidationRules,
  password: passwordValidationRules,
  confirm_password: confirm_password_ValidationRules,
  has_iso: NoValidate,
  iso_id: NoValidate,
  iso_name: NoValidate,
  material: NoValidate,
  service: NoValidate,
  iso: NoValidate,
  terms: terms_ValidationRules,
};
const formValidation = (values, errors, touched) => {
  return Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
};

export { formValidation };
