const NoValidate = () => null;

const validate = {
  company_presentation: NoValidate,
  differentials: NoValidate,
  additional_information: NoValidate,
  renderedService: NoValidate,
  facebook: NoValidate,
  instagram: NoValidate,
  linkedin: NoValidate,
  website: NoValidate,
};
const formValidation = (values, errors, touched) => {
  return Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
};

export { formValidation };
