import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 2;
  width: 100%;
  padding: 10px;
  padding-right: 50px;
  background: ${theme.colors.color_2};
`;

export const Title = styled.div`
  color: white;
  ${theme.font.text};
  padding: 5px 10px;
`;

export const ColInfo = styled.div`
  background: ${theme.colors.color_2};
  color: white;
  ${theme.font.text};
  padding: 10px;
  text-align: center;
  line-height: 90%;
`;

export const ListBox = styled.div`
  width: 100%;
  padding: 0 10px 0 0;
`;

export const ListBoxScroll = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: relative;
  padding: 0 0;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.colors.color_5};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.color_2};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ListItem = styled.div`
  background: ${theme.colors.color_1};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-right: 50px;
  margin: 15px 0;
`;

export const ListItemTitle = styled.div`
  ${theme.font.normal};
  font-size: 18px;
`;

export const ListItemButton = styled(Link)`
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;
