const customer_information_ValidationRules = (value) => {
  if (value.trim() === '') return 'O campo Customer é obrigatório';
  return null;
};
const service_information_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Serviço/Material é obrigatório';
  return null;
};
const start_date_information_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Inicio de Contrato é obrigatório';
  return null;
};
const end_date_information_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Fim de Contrato é obrigatório';
  return null;
};
// const NoValidate = () => null;

const validate = {
  customer: customer_information_ValidationRules,
  service: service_information_ValidationRules,
  start_date: start_date_information_ValidationRules,
  end_date: end_date_information_ValidationRules,
};
const formValidation = (values, errors, touched) => {
  return Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
};

export { formValidation };
