import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuardedRoute = ({ component: Component, auth, user, ...rest }) => {
  const _user = JSON.parse(localStorage.getItem('user'));

  return (
    <Route
      {...rest}
      render={(props) => {
        const checkIsUserAprove = () => {
          if (_user.role.find((p) => p !== 'provider')) return true;
          return _user.aprove;
        };
        if (
          auth &&
          _user &&
          !_user.completeRegistration &&
          checkIsUserAprove() &&
          props.location.pathname !== '/completar-cadastro'
        ) {
          return <Redirect to="/completar-cadastro" />;
        }
        if (auth && checkIsUserAprove()) {
          return <Component {...props} />;
        }
        if (_user && !checkIsUserAprove()) {
          return <Redirect to="/cadastro-em-avaliacao" />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

export default GuardedRoute;
