import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  background-color: ${theme.colors.color_2};
  ${theme.font.title};
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
`;
