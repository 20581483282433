import React from 'react';
import { Wrapper, Container } from './styles';
import Menu from '../../components/Menu';
import RequestsList from '../../components/RequestsList';
import { getRequestsList } from '../../services/requests.service';

export default function index() {
  const [requestList, setrequestList] = React.useState();

  React.useEffect(async () => {
    const reviews = await getRequestsList();
    setrequestList(reviews.data);
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <RequestsList loading={!requestList} list={requestList} />
        </Wrapper>
      </Menu>
    </Container>
  );
}
