import React from 'react';
import { history } from '../../helpers/history';

import {
  Container,
  Form,
  Label,
  ButtonBox,
  Button,
  ErrorLabel,
  Textarea,
  Input,
  Subtitle,
  Title,
  TitleWrapper,
  TitleIconWrapper,
  TitleFirstLine,
  TitleSecondLine,
  SubLabel,
} from './styles';

import { formValidation } from './validation';
import { completeProvider } from '../../services/provider.service';

export default function CompleteRegisterForm() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [submitErrors, setSubmitErrors] = React.useState(null);
  const [touched, setTouched] = React.useState({});
  const [values, setValues] = React.useState({
    company_presentation: undefined,
    differentials: undefined,
    additional_information: undefined,
    renderedService: undefined,
    facebook: undefined,
    instagram: undefined,
    linkedin: undefined,
    website: undefined,
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) {
      const toSend = {
        socialNetwork: {
          facebook: values.facebook,
          instagram: values.instagram,
          linkedin: values.linkedin,
          website: values.website,
        },
        presentation: {
          company_presentation: values.company_presentation,
          differentials: values.differentials,
          additional_information: values.additional_information,
          renderedService: values.renderedService,
        },
      };

      await completeProvider(user.id, toSend)
        .then(() => {
          setLoading(false);
          localStorage.setItem(
            'user',
            JSON.stringify({ ...user, completeRegistration: true })
          );
          history.push('/dashboard');
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          setSubmitErrors(JSON.stringify(err.response.data.errors));
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <Container>
      <TitleWrapper>
        <TitleIconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="75"
            height="75"
            viewBox="0 0 24 24"
            fill="#D90D00"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>
        </TitleIconWrapper>
        <Title>
          <TitleFirstLine>Seja bem-vindo!</TitleFirstLine>
          <TitleSecondLine>Seu cadastro foi aprovado!</TitleSecondLine>
        </Title>
      </TitleWrapper>

      <Form onSubmit={handleSubmit} autoComplete="off">
        <Subtitle>
          Complemente seu cadastro com informações que podem ser úteis para os
          usuários:
        </Subtitle>

        <Label htmlFor="company_presentation">
          Apresentação da Empresa
          <Textarea
            id="company_presentation"
            type="text"
            name="company_presentation"
            value={values.company_presentation}
            onChange={handleChange}
            rows="4"
          />
          <ErrorLabel
            show={touched.company_presentation && errors.company_presentation}
          >
            {errors.company_presentation}
          </ErrorLabel>
        </Label>

        <Label htmlFor="differentials">
          Diferenciais
          <Textarea
            id="differentials"
            type="text"
            name="differentials"
            value={values.differentials}
            onChange={handleChange}
            rows="4"
          />
          <ErrorLabel show={touched.differentials && errors.differentials}>
            {errors.differentials}
          </ErrorLabel>
        </Label>

        <Label htmlFor="additional_information">
          Informaçõs Adicionais
          <Textarea
            id="additional_information"
            type="text"
            name="additional_information"
            value={values.additional_information}
            onChange={handleChange}
            rows="4"
          />
          <ErrorLabel
            show={
              touched.additional_information && errors.additional_information
            }
          >
            {errors.additional_information}
          </ErrorLabel>
        </Label>
        <Label htmlFor="renderedService">
          Serviços prestados anteriormente à Civil
          <SubLabel>
            Indique a unidade, serviço prestado e contato da unidade
          </SubLabel>
          <Textarea
            id="renderedService"
            type="text"
            name="renderedService"
            value={values.renderedService}
            onChange={handleChange}
            rows="4"
          />
          <ErrorLabel show={touched.renderedService && errors.renderedService}>
            {errors.renderedService}
          </ErrorLabel>
        </Label>
        <Label htmlFor="website">
          Website
          <Input
            id="website"
            type="text"
            name="website"
            value={values.website}
            onChange={handleChange}
          />
          <ErrorLabel show={touched.website && errors.website}>
            {errors.website}
          </ErrorLabel>
        </Label>
        <>
          <Subtitle>Redes Sociais</Subtitle>
          <Label htmlFor="facebook">
            Facebook
            <Input
              id="facebook"
              type="text"
              name="facebook"
              value={values.facebook}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.facebook && errors.facebook}>
              {errors.facebook}
            </ErrorLabel>
          </Label>
          <Label htmlFor="instagram">
            Instagram
            <Input
              id="instagram"
              type="text"
              name="instagram"
              value={values.instagram}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.instagram && errors.instagram}>
              {errors.instagram}
            </ErrorLabel>
          </Label>
          <Label htmlFor="linkedin">
            Linkedin
            <Input
              id="linkedin"
              type="text"
              name="linkedin"
              value={values.linkedin}
              onChange={handleChange}
            />
            <ErrorLabel show={touched.linkedin && errors.linkedin}>
              {errors.linkedin}
            </ErrorLabel>
          </Label>
        </>

        <ButtonBox>
          <ErrorLabel show={submitErrors}>{submitErrors}</ErrorLabel>
          <Button disabled={loading} type="submit">
            {loading && <span className="spinner-border spinner-border-sm" />}
            {!loading && <>Pronto!</>}
          </Button>
        </ButtonBox>
      </Form>
    </Container>
  );
}
