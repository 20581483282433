import styled from 'styled-components';
import theme from '../../../../helpers/theme';

export const Button = styled.button`
  align-items: center;
  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.active
      ? props.highlighted
        ? theme.colors.color_2
        : theme.colors.color_2_5
      : theme.colors.color_1};
  border: 1px solid white;
  color: ${(props) => (props.active ? 'white' : theme.colors.color_2_5)};
  display: flex;
  height: 40px;
  width: 50px;
  justify-content: center;
  outline: none;
  padding: 5px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
`;
