const emailValidationRules = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'O email é obrigatório';
  }
  return 'Por favor digite um email válido';
};
const user_type_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Tipo de usário é obrigatório';
  return null;
};
const validate = {
  email: emailValidationRules,
  user_type: user_type_ValidationRules,
};
const formValidation = (values, errors, touched) =>
  Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
export { formValidation };
