import React from 'react';
import { connect } from 'react-redux';
import { Container } from './styles';
import RegisterForm from '../../components/RegisterForm';
import { getStates } from '../../services/states.service';
import { getSearchList } from '../../services/search.service';

const Register = () => {
  const [states, setStates] = React.useState();
  const [materialAndServiceList, setMaterialAndServiceList] = React.useState();

  React.useEffect(async () => {
    const statesData = await getStates();
    const materialAndServiceData = await getSearchList();
    setMaterialAndServiceList(materialAndServiceData.data);
    setStates(statesData.data);
  }, []);
  return (
    <Container>
      <RegisterForm
        states={states}
        materialAndService={materialAndServiceList}
      />
    </Container>
  );
};

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Register);
