import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;
export const Title = styled.div`
  background: ${theme.colors.color_2};
  color: white;
  ${theme.font.text};
  text-transform: uppercase;
  padding: 2px 5px;
  margin-left: 20px;
  margin-bottom: -10px;
  z-index: 2;
`;

export const Line = styled.div`
  background: ${theme.colors.color_2_5};
  width: 50%;
  height: 1px;
  margin: 0 auto;
  margin-bottom: 5px;
`;

export const ListBox = styled.div`
  background: ${theme.colors.color_1};
  border-radius: 30px;
  max-width: 600px;
  min-width: 300px;
  padding: 20px 10px 10px 0;
  ${(props) =>
    props.hasItens
      ? `height: 500px;`
      : `  min-height: 200px;
`}
`;

export const ListBoxScroll = styled.div`
  border-radius: 30px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: relative;
  padding: 0 20px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.colors.color_5};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.color_2};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ListItem = styled.div`
  border-bottom: 1px solid ${theme.colors.color_2};
  padding: 10px;
  ${theme.font.normal};
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.isColumn ? 'flex-direction: column' : 'flex-direction: row'};
`;

export const ExperienceWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Button = styled(Link)`
  margin-left: 15px;
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;
