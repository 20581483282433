const provider_password_ValidationRules = (value) => {
  if (!value || value.trim() === '') return 'O campo fornecedor é obrigatório';
  return null;
};
const start_date_password_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Data de entrada da nota é obrigatório';
  return null;
};
const enginner_ValidationRules = (value) => {
  if (!value || value.trim() === '')
    return 'O campo Engenheiro Responsável é obrigatório';
  return null;
};

// const NoValidate = () => null;

const validate = {
  enginner: enginner_ValidationRules,
  provider: provider_password_ValidationRules,
  start_date: start_date_password_ValidationRules,
};
const formValidation = (values, errors, touched) => {
  return Object.keys(values).reduce(
    (acc, key) => {
      const newError = validate[key](values[key]);
      const newTouched = { [key]: true };
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError }),
        },
        touched: {
          ...acc.touched,
          ...newTouched,
        },
      };
    },
    {
      errors: { ...errors },
      touched: { ...touched },
    }
  );
};

export { formValidation };
