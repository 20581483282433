import styled from 'styled-components';
import { ToggleButton } from 'react-bootstrap';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Label = styled.label`
  ${theme.font.title}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 20px;
`;

export const LabelText = styled.span`
  width: 150px;
`;

export const Input = styled.input`
  outline: none;
`;

export const NPSWrapper = styled.div`
  display: inline-flex;
  padding-left: 20px;
`;

export const SubTitle = styled.div`
  ${theme.font.title};
  margin: 15px 0;
  font-size: 20px;
`;

export const ExperienceSubTitle = styled.div`
  width: fit-content;
  background-color: ${theme.colors.color_2};
  color: white;
  ${theme.font.title};
  margin: 15px 0;
  font-size: 20px;
  padding: 0 5px;
`;

export const ExperienceWrapper = styled.div`
  background-color: ${theme.colors.color_1};
  padding: 20px 30px;
`;

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  width: 40vw;
  padding: 5px 15px;
  min-height: 100px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Submit = styled.button`
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;

export const ThemedToggleButton = styled(ToggleButton)`
  border-radius: 0;
  background-color: ${theme.colors.color_1};
  border-color: white;
  color: ${theme.colors.color_2_5};
  &.active {
    background-color: ${theme.colors.color_2} !important;
    border-color: ${theme.colors.color_2} !important;
  }
`;

export const ErrorWrapper = styled.div`
  color: white;
  ${theme.font.text};
  background-color: ${theme.colors.color_2};
  margin-top: 30px;
  display: flex;
  padding: 5px 10px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
