import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RedirectIfAuthenticated = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth === false ? <Component {...props} /> : <Redirect to="/dashboard" />
    }
  />
);

export default RedirectIfAuthenticated;
