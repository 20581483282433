import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: 70%;
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/images/hexagon_background.png');
  background-position-x: 110%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  transform: scaleY(-1);
  filter: FlipV;
`;

export const BodyWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const AveragesContainer = styled.div`
  width: 500px;
  background-color: ${theme.colors.color_1};
  padding: 20px;
  padding-right: 0;
  border-radius: 30px;
`;

export const ListWrapper = styled.div``;

export const ResumeRowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
`;

export const ResumeRowLabel = styled.div`
  ${theme.font.title};
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  width: 45%;
`;

export const ResumeRowBar = styled.div`
  width: 100%;
`;

export const AveragesHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const AveragesTitle = styled.div`
  ${theme.font.title};
  font-size: 20px;
  color: ${theme.colors.color_5};
`;

export const AveragesSubTitle = styled.div`
  ${theme.font.title};
  font-size: 30px;
  background-color: ${theme.colors.color_5};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
`;

export const AveragesWrapper = styled.div`
  width: 100%;
`;
