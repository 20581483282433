import React from 'react';
import { connect } from 'react-redux';
import { Container, Background, AereaLabel } from './styles';
import RemeberMyPasswordBox from '../../components/RemeberMyPasswordBox';
import UserService from '../../services/user.service';

const RemeberPassword = () => {
  return (
    <Container>
      <Background />
      <AereaLabel>Lembrar minha senha</AereaLabel>
      <RemeberMyPasswordBox createPwdRecovery={UserService.createPwdRecovery} />
    </Container>
  );
};

export default connect()(RemeberPassword);
