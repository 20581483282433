import React from 'react';
import { Button } from './styles';

export default function ScoreButton(props) {
  const { buttonScore, hoveredScore, onMouseEnter, onSubmit, setState } = props;
  return (
    <Button
      active={hoveredScore >= buttonScore}
      highlighted={hoveredScore === buttonScore}
      onClick={onSubmit(buttonScore, setState)}
      onMouseEnter={onMouseEnter(buttonScore, setState)}
    >
      {buttonScore}
    </Button>
  );
}
