import React from 'react';
import {
  Container,
  Label,
  Input,
  ButtonBox,
  Button,
  ErrorLabel,
  Select,
} from './styles';

import { formValidation } from './validation';

export default function RemeberMyPasswordBoxComponent(props) {
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [emailSent, setEmailSent] = React.useState(false);
  const [values, setValues] = React.useState({
    email: '',
    user_type: 'provider',
  });

  const handleChange = (evt) => {
    const { name, value: newValue, type } = evt.target;

    const value = type === 'number' ? +newValue : newValue;

    setValues({
      ...values,
      [name]: value,
    });

    setTouched({
      ...touched,
      [name]: true,
    });

    if (errors) {
      const updatedErrors = errors;
      delete updatedErrors[name];
      setErrors(updatedErrors);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setErrors(formValidation(values, errors, touched).errors);
    setTouched(formValidation(values, errors, touched).touched);

    const formIsValid =
      !Object.values(formValidation(values, errors, touched).errors).length &&
      Object.values(formValidation(values, errors, touched).touched).length ===
        Object.values(values).length &&
      Object.values(formValidation(values, errors, touched).touched).every(
        (isTouched) => isTouched === true
      );

    if (formIsValid) {
      await props
        .createPwdRecovery(values)
        .then(() => {
          setEmailSent(true);
        })
        .catch(() => {
          // setSubmitErrors(JSON.stringify(err.response.data.errors));
        });
    }
  };

  return (
    <Container onSubmit={handleSubmit} autoComplete="off">
      {!emailSent && (
        <>
          <Label htmlFor="email">
            Email cadastrado:
            <Input
              id="email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <ErrorLabel show={touched.email && errors.email}>
              {errors.email}
            </ErrorLabel>
          </Label>
          <Label htmlFor="user_type">
            Tipo de usuário
            <Select
              id="user_type"
              type="text"
              name="user_type"
              value={values.user_type}
              onChange={handleChange}
              required
            >
              <option value="provider">Fornecedor</option>
              <option value="client">Civil</option>
              <option value="csc">Civil CSC</option>
              <option value="admin">Civil Admin</option>
            </Select>
            <ErrorLabel show={touched.user_type && errors.user_type}>
              {errors.user_type}
            </ErrorLabel>
          </Label>

          <ButtonBox>
            <ErrorLabel show={props.errorMessage}>
              {props.errorMessage}
            </ErrorLabel>
            <Button disabled={props.loading} type="submit">
              {props.loading && (
                <span className="spinner-border spinner-border-sm" />
              )}
              {!props.loading && <>Enviar</>}
            </Button>
          </ButtonBox>
        </>
      )}
      {emailSent && <>E-mail enviado. Confira seu e-mail.</>}
    </Container>
  );
}
