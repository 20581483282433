import React from 'react';
import { Wrapper, Container } from './styles';
import Menu from '../../components/Menu';
import MessagesList from '../../components/MessagesList';
import {
  getCustomerMessages,
  getProviderMessages,
} from '../../services/messages.service';

export default function index() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [messageList, setMessageList] = React.useState();

  React.useEffect(async () => {
    let messages = null;
    if (user.role.find((r) => r === 'client'))
      messages = (await getCustomerMessages()).data.unrated_reviews;
    if (user.role.find((r) => r === 'provider'))
      messages = (await getProviderMessages()).data.unrateds_evaluatecivil;

    setMessageList(messages);
  }, []);

  return (
    <Container>
      <Menu>
        <Wrapper>
          <MessagesList loading={!messageList} list={messageList} />
        </Wrapper>
      </Menu>
    </Container>
  );
}
