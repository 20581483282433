import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: 600px;
  margin: 10% auto 0;
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/images/hexagon_background.png');
  background-position-x: 110%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  transform: scaleY(-1);
  filter: FlipV;
`;

export const Title = styled.div`
  background-color: ${theme.colors.color_2};
  ${theme.font.title};
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 18px;
`;

export const StatusBarRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 40px;
`;

export const StatusBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 5px;
`;

export const StatusBar = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.completed ? '#00b85b' : theme.colors.color_2};
  height: 15px;
  border-radius: 15px;
`;

export const StatusBarLabel = styled.div`
  ${theme.font.title};
  text-align: center;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
`;

export const Button = styled(Link)`
  ${theme.font.text};
  font-size: 15px;
  background: white;
  border: 1px solid ${theme.colors.color_3};
  color: ${theme.colors.color_2};
  text-transform: uppercase;
  padding: 3px 40px;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_3};
    transition: 0.3s;
  }
`;
