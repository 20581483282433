import React from 'react';
import { Container, Wrapper } from './styles';
import Menu from '../../components/Menu';
import ProfileForm from '../../components/ProfileForm';
import UserService from '../../services/user.service';
import { getStates } from '../../services/states.service';
import { getSearchList } from '../../services/search.service';

export default function index() {
  const [profile, setProfile] = React.useState();
  const [states, setStates] = React.useState();
  const [materialAndServiceList, setMaterialAndServiceList] = React.useState();

  React.useEffect(async () => {
    const profileData = await UserService.getMe();
    const statesData = await getStates();
    const materialAndServiceData = await getSearchList();
    setMaterialAndServiceList(materialAndServiceData.data);
    setStates(statesData.data);
    setProfile(profileData.data);
  }, []);
  return (
    <Container>
      <Menu>
        <Wrapper>
          {!!profile && !!states && !!materialAndServiceList && (
            <ProfileForm
              profile={profile}
              states={states}
              materialAndService={materialAndServiceList}
            />
          )}
        </Wrapper>
      </Menu>
    </Container>
  );
}
