import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
export const Left = styled.div``;

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  background: #c3c3c3;
  border-radius: 50%;
`;

export const Right = styled.div`
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const UserTitle = styled.div`
  ${theme.font.title};
  color: ${theme.colors.color_2};
  font-size: 30px;
`;

export const UserDescription = styled.div`
  ${theme.font.normal};
  color: ${theme.colors.color_5};
  font-size: 20px;
`;
