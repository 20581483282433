import styled from 'styled-components';
import theme from '../../helpers/theme';

export const Container = styled.div``;
export const Wrapper = styled.div`
  padding: 20px 40px;
  width: calc(100% - 250px);
`;
export const Title = styled.h2`
  ${theme.font.title};
  color: ${theme.colors.color_2};
  font-size: 24px;
  margin: 0;
`;
