import axios from 'axios';
import authHeader from './auth-header';

const getCustomerList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}customers`, {
    headers: authHeader(),
  });
};

const getEngineerList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}customers/all_engineers`, {
    headers: authHeader(),
  });
};

const createCustomer = (country, state, city, body) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}customers?country=${country}${
      state ? `&state=${state}` : ''
    }${state ? `&city=${city}` : ''}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const editCustomer = (id, country, state, city, body) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}customers/${id}?country=${country}&state=${state}&city=${city}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

export { getCustomerList, createCustomer, editCustomer, getEngineerList };
