import axios from 'axios';
import authHeader from './auth-header';

const getProvider = (id) => {
  return axios.get(`${process.env.REACT_APP_API_URL}providers/${id}`, {
    headers: authHeader(),
  });
};

const getProviderList = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}providers`, {
    headers: authHeader(),
  });
};

const createProvider = (country, state, city, body) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}providers?country=${country}&state=${state}&city=${city}`,
      body
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const aproveProvider = (provider_id, aprove) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}providers/acceptance/${provider_id}`,
    {
      aprove,
    },
    {
      headers: authHeader(),
    }
  );
};

const completeProvider = (provider_id, body) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}providers/info/${provider_id}`,
    body,
    {
      headers: authHeader(),
    }
  );
};
const editProvider = (provider_id, body) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}providers/${provider_id}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const getAdminReport = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}providers/report/pdf/administration`,
    {
      responseType: 'blob',
      headers: authHeader(),
    }
  );
};

export {
  getProvider,
  createProvider,
  aproveProvider,
  completeProvider,
  getProviderList,
  editProvider,
  getAdminReport,
};
