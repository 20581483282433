import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../helpers/theme';

export const Header = styled.header`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 8px solid ${theme.colors.color_1};
  padding: 15px;
  z-index: 99;
  position: relative;
`;

export const Logo = styled.img``;

export const LeftBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightBox = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Subtitle = styled.div`
  ${theme.font.normal}
  text-transform: uppercase;
  background-color: ${theme.colors.color_2};
  color: white;
  font-size: 32px;
  padding: 5px 10px;
  margin-left: 20px;
  letter-spacing: -1px;
`;

export const Menu = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li``;

export const MenuItemLink = styled(Link)`
  ${theme.font.text}
  color: ${theme.colors.color_3};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  padding: 0px 15px;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;

export const MenuItemExternalLink = styled.a`
  ${theme.font.text}
  color: ${theme.colors.color_3};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  padding: 0px 15px;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.color_2};
    transition: 0.3s;
  }
`;
