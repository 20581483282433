import React from 'react';
import { Container } from './styles';
import CompleteRegisterForm from '../../components/CompleteRegisterForm';

export default function CompleteRegister() {
  return (
    <Container>
      <CompleteRegisterForm />
    </Container>
  );
}
