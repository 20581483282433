import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './types';

import AuthService from '../services/auth.service';

export const register = (name, cpf_cnpj, email, password, permission) => (
  dispatch
) => {
  return AuthService.register(name, cpf_cnpj, email, password, permission).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user: response,
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const registerProvider = (
  fantasy_name,
  social_reason,
  address_street,
  address_postalCode,
  contact_cellphone,
  contact_telephone,
  country,
  state,
  city,
  seal,
  service,
  material,
  user
) => (dispatch) => {
  return AuthService.registerProvider(
    fantasy_name,
    social_reason,
    address_street,
    address_postalCode,
    contact_cellphone,
    contact_telephone,
    country,
    state,
    city,
    seal,
    service,
    material,
    user
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: {
          user: response,
        },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password, user_type) => (dispatch) => {
  return AuthService.login(email, password, user_type)
    .then((data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: data,
        },
      });
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(message);
    });
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
